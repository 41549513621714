.text-capitalize{
  text-transform: capitalize;
}
.text-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

/* YKB cms page */

.cmsPageTitle {
    font-size: 26px;
    margin: 20px 0px 12px 0px;
}

.cmsPageLine {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 32px;
    margin-top: 10px;
}

/*.fblogin {
  background-color: #3b5998;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
}

.fbloginDiv {
  float: left;
}*/

.clistitemul{
  list-style-type:none;
  white-space:nowrap;
  overflow-x:auto;
  overflow-y:hidden;
  padding: 0;
  margin: 0 0 5px 0;
  clear: both;
}

.clistitemul li{
  display:inline-block;
  list-style: none;
  border: 1px solid #d6dce0;
  padding: 0px;
  margin: 5px;
  border-radius: 2px;
  line-height: 25px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}


.clistitemul .md-list-item-inner{
  white-space: nowrap;
}

.clistitemul .md-list-item-inner{
  padding: 0px 7px !important;
  font-size: 16px !important;
}

.clistitemul .md-button{
  padding: 0px !important;
}

.btn-label.active.focus,
.btn-label.active:focus,
.btn-label.active:hover,
.btn-label.focus:active,
.btn-label:active:focus,
.btn-label:active:hover{
    color: #333;
    background-color: #d4d4d4 !important;
    border-color: #8c8c8c !important;
}

.cl-cont{
  font-size: 16px;
}

.cl-cont md-input-container{
  display: block !important;
  float: left;
  width: 100%;
  margin: 0px;
}

.cl-cont label{
  font-weight:normal;
}

.del-lab{
  font-size: 25px;
  padding: 0px 5px;
  line-height: 1;
}

.multiline-tip{
  height: auto;
}