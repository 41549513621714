@font-face {
  font-family: "sugarbushregular";
  src: url("../fonts/sugarbush-regular-webfont.eot");
  src: url("../fonts/sugarbush-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/sugarbush-regular-webfont.woff2") format("woff2"),
    url("../fonts/sugarbush-regular-webfont.woff") format("woff"),
    url("../fonts/sugarbush-regular-webfont.ttf") format("truetype"),
    url("../fonts/sugarbush-regular-webfont.svg#sugarbushregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
body,
html {
  background-color: #ebeef0 !important;
  background: #ebeef0 !important;
}
body {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  background-color: #ebeef0 !important;
  outline: none;
}
body *,
body *:hover,
body *:focus {
  outline: none;
}
a,
a:hover,
a:focus {
  outline: none;
  color: inherit;
  text-decoration: none;
}
ui-sref {
  cursor: pointer;
}
.txtcross {
  text-decoration: line-through;
}
.modal-open .navbar-fixed-top {
  width: calc(100% - 17px);
}
.required-astrik {
  color: rgba(255, 0, 0, 1);
}
.fullwidth {
  width: 100%;
  float: left;
}
.cursor-pointer {
  cursor: pointer;
}
.addcart-btncover .fa-spinner {
  position: relative;
  left: -12px;
  font-size: 17px;
  top: 1px;
}
.header {
  /*background: url(../images/desktopbg.jpg) no-repeat center top;*/
  min-height: 690px;
  background-size: 100%;
  /*background-size: auto 690px;*/
  /*padding-top:15px;*/
  margin-bottom: 20px;
}
.onthego_block {
  background: url(../images/yellow_bg.jpg) no-repeat center top;
  min-height: 369px;
  background-size: 100% 369px;
}
/*md-dialog.md-transition-in{
	background: transparent;
	box-shadow: 0 0 0 0;
}*/

.social_block {
  background: #aa00ff;
}
.footer1 {
  background-color: #222328;
}
.footer2 {
  background-color: #222328;
}
.text-center img {
  display: inline-block;
}
.nopadd {
  padding-left: 0;
  padding-right: 0;
}
.navbar-default .navbar-nav li {
  float: left;
  width: 100%;
}
.navbar-default .navbar-nav li .caret {
  float: right;
  border: none;
  background: url(../images/caret_r.png) no-repeat center center;
  width: 7px;
  height: 20px;
}
.navbar-default .navbar-nav li a {
  padding: 5px 0px;
  color: #363535;
  font-size: 12px;
  font-weight: 600;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background-color: transparent;
  color: #aa00ff;
  font-weight: 600;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent;
  color: #aa00ff;
}
.modify .dropdown-menu {
  left: 101%;
  top: 0;
  padding: 28px;
  z-index: 999999;
  border-radius: 5px !important;
  border: none;
  box-shadow: 0 10px 60px rgba(86, 111, 140, 0.6);
  background: #ffffff url(../images/drop_bg.png) no-repeat 95% 107%;
  background-size: auto 200px;
  min-width: 520px;
  min-height: 0 /*372px*/;
  min-height: 280px;
}
.navbar-default {
  background-color: transparent;
  border: none;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  background-color: transparent;
}
.dm_title {
  width: 100% !important;
  float: left;
  font-size: 24px;
  color: #aa00ff;
  padding: 0px 0px 8px 0px;
}
.modify .dropdown-menu li {
  width: 32%;
  margin-right: 2%;
}
.modify .dropdown-menu li a {
  color: #363535;
  font-size: 12px;
  padding: 8px 8px;
  line-height: 18px;
}
.modify .dropdown-menu li a span {
  font-size: 10px;
  color: #a5a5a5;
  display: inline-block;
  border: 1px solid #a5a5a5;
  border-radius: 3px;
  padding: 3px;
  margin-left: 8px;
  min-width: 15px;
  text-align: center;
  line-height: 10px;
}

.homecallus {
  color: rgba(44, 32, 17, 0.5);
  width: 100%;
  float: left;
  text-transform: uppercase;
  line-height: 10px;
  margin-top: 0;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.homechilled {
  width: 100%;
  float: left;
  color: #2c2011;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20px;
}
.btm10 {
  margin-bottom: 10px;
}
.signuplogin {
  font-weight: 500;
  color: #2c2011;
  text-transform: uppercase;
  width: 100%;
  float: left; /*	-moz-transition:all 0.3s ease 0s;
	-webkit-transition:all 0.3s ease 0s;  
	transition:all 0.3s ease 0s;*/
}
.signuplogin a,
.signuplogin > a:hover,
.signuplogin a:focus {
  color: #2c2011;
  text-decoration: none;
  padding: 32.5px 15px;
  display: inline-block;
  float: left; /*	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;*/
}
.signuplogin a:hover {
  /*background-color: #e1b11f;*/
}
.dropdown.cart-icon-set span:hover {
  background-color: #e1b11f;
}
.searchtop {
  width: 21px;
  height: 21px;
  float: right;
  /*padding:0 15px;*/
  position: absolute;
  top: 41px;
  right: 27.5%;
  z-index: 9;
  transition: all 0.3s ease 0s;
}
.signuplogin .currentorderpopuppinkboxright > a,
.signuplogin .currentorderpopuppinkboxright > a:hover,
.signuplogin .currentorderpopuppinkboxright a:focus {
  padding: 2px !important;
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: #fff;
  background: none !important;
}
.signuplogin .currentorderpopuppinkboxright > a:hover,
.signuplogin .currentorderpopuppinkboxright a:focus {
  box-shadow: 2px 2px 5px 0px rgba(12, 12, 12, 0.49);
}
.searchtop100 {
  width: 100%;
  right: 0%;
}
.search_icon {
  background: url(../images/search.svg) no-repeat center 41px;
  width: 21px;
  height: 21px;
  float: left;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 10;
  top: 0;
  margin-right: 0px;
  padding: 40px 20px 60px !important;
  cursor: pointer;
}

.navbar-shrink .search_icon {
  padding: 35px 20px 35px !important;
}

.searchtop.searchtop100 .search_icon {
  top: 0;
  background: rgba(0, 0, 0, 0) url("../images/sprite.png") no-repeat scroll -308px
    0;
}
.searchtop input {
  border: none;
  outline: none;
  width: 95%;
  float: left;
  height: 21px;
  background-color: transparent;
  /*display:none;*/
  padding: 0 5px;
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.searchtop100 input {
  opacity: 1;
  transition: all 0.3s ease 0s;
}
.search_close,
.search_close:hover,
.search_close:focus {
  float: right;
  /*display:none;*/
  text-decoration: none;
  color: #2c2011;
  font-size: 28px;
  margin-top: -9px;
  opacity: 0;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.search_close_opaque,
.search_close_opaque:hover,
.search_close_opaque:focus {
  opacity: 0.7;
}
.cart_icon {
  background: url(../images/cart.svg) no-repeat center 41px;
  width: 23px;
  height: 20px;
  min-height: 100px;
  min-width: 41px;
  background-size: 24px;
  cursor: pointer;
}
/*.cart_icon:hover {
	background: #e1b11f url(../images/sprite.png) no-repeat -340px 33px !important;
}*/
.homecallus_cover {
  /*	width:135px;
*/
  background: #ffc412 none repeat scroll 0 0;
  display: flex;
  height: 100%;
  align-items: center;
  left: 0;
  opacity: 1;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  transition: all 0.5s ease 0s;
  z-index: 2;
}
.callshape {
  position: relative;
  top: 0;
  left: 0;
}
.logo_cover {
  width: 765px;
  padding-right: 0px;
  padding-left: 145px;
  position: relative;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  z-index: 1;
}
.signuplogin_cover {
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
  float: right;
  right: 0%;
  max-width: 36%;
  width: 36%;
}
.logoss {
  position: relative;
  min-width: 64%;
  float: left;
  text-align: center;
  transition: all 0.3s ease 0s;
  left: 0%;
  opacity: 1;
  min-height: 80px;
}
.leftminusopacity {
  transition: all 0.5s ease 0s;
  opacity: 0;
}
.leftminus100 {
  left: -100%;
  transition: all 0.5s ease 0s;
}
.leftminus2100 {
  left: -100%;
  transition: all 0.5s ease 0s;
}
.rightminus100 {
  right: -100%;
  transition: all 0.5s ease 0s;
}
.again0left {
  left: 0%;
  transition: all 0.5s ease 0s;
}
.again0right {
  right: 0%;
  transition: all 0.5s ease 0s;
}
.again21 {
  width: 21px;
  transition: all 0.5s ease 0s;
}
.againopacity {
  opacity: 1;
  transition: all 0.5s ease 0s;
}
.occassion_sale {
  width: 100%;
  float: left;
  /*font-size: 148px;*/
  font-size: 110px;
  color: #ef5350;
  font-family: "sugarbushregular";
  text-align: center;
  /*padding-top: 30px;*/
  line-height: 120px;
}
.occassion_date {
  width: 100%;
  float: left;
  font-size: 26px;
  color: #ef5350;
  text-align: center;
  /*text-transform: uppercase;*/
  letter-spacing: 3px;
  font-weight: bold;
}
.occassion_descrip {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #2c2011;
  text-align: center;
  margin-top: 32px;
}
.registernowbtn {
  width: 100%;
  float: left;
  text-align: center;
  /*margin-top: 60px;*/
  margin-top: 65px;
  margin-bottom: 42px;
}
.registernowbtn a,
.registernowbtn a:hover,
.registernowbtn a:focus {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #2c2011;
  border: 2px solid #ef5350;
  border-radius: 4px;
  width: 202px;
  height: 54px;
  line-height: 50px;
  text-transform: uppercase;
  text-decoration: none;
}
.combo_slider {
  width: 100%;
  float: left;
  background: #ffffff;
  border: 1px solid #dfe2e4;
  border-top: none;
  border-radius: 5px;
  padding: 15px 0;
}
.innerpagemargintop .combo_slider {
  margin-bottom: 20px;
}
/*------owl-carousel-----------*/

.tabtitle {
  width: 20%;
  float: left;
  padding-right: 10px;
  padding-left: 24px;
  padding-top: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #010101;
}
.newfeature-text {
  color: #656565;
  font-size: 28px;
  font-weight: 500;
  padding-left: 24px;
  padding-right: 10px;
  padding-top: 0;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
.tabsright {
  width: 70%;
  margin-right: 35px;
  float: right;
  position: relative;
}
.tabsright .owl-theme .owl-controls {
  left: 0;
  margin-top: 0;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 100%;
  z-index: 1;
}
.tabsright .owl-theme .owl-controls .owl-prev {
  float: left;
  left: -35px;
  position: relative;
}
.tabsright .owl-theme .owl-controls .owl-next {
  float: right;
  right: -30px;
  position: relative;
}
.tabsright .owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}
.combo_slider .item {
  background: transparent;
  border-radius: 0px;
  color: #ffffff;
  margin: 24px;
  padding: 0px 0;
  text-align: center;
  float: left;
}
.combo_slider .nav-tabs li a {
  border: 0px solid transparent;
  border-radius: none;
  color: #5f5f5f;
}
.combo_slider .nav-tabs li.active a,
.combo_slider .nav-tabs li.active a:focus,
.combo_slider .nav-tabs li.active a:hover {
  background-color: transparent;
  border-radius: none;
  border: 0px solid transparent;
  border-bottom: 2px solid #aa00ff !important;
  color: #aa00ff;
  cursor: default;
}
.combo_slider .nav li a:focus,
.combo_slider .nav li a:hover {
  background-color: transparent;
  text-decoration: none;
  border: 0px solid transparent;
  /*border-bottom: 2px solid #aa00ff !important;*/
  color: #aa00ff;
  outline: none;
}
.combo_slider .nav-tabs {
  border-bottom: 0px solid #ddd;
}
.combo_slider.main .nav li a {
  padding: 8px 0;
  margin: 0 0px;
  text-transform: capitalize;
  font-size: 1em;
  border-bottom: solid 2px #ffffff;
}
/*.tabsright .owl-carousel .owl-wrapper-outer{
	margin-top:9px;
}*/

.tabsright .owl-carousel .owl-item {
  padding: 9px 0;
}
.prod_chilled {
  width: 100%;
  min-width: 80px;
  float: left;
  margin-bottom: 15px;
  cursor: pointer;
  overflow: hidden;
  text-align: right;
}
.cart-table .prod_chilled {
  margin-bottom: 0px;
}
.prod_chilled_icon {
  display: inline-block;
  float: none;
  vertical-align: middle;
  background: url(../images/chilled.svg) no-repeat;
  min-width: 24px;
  min-height: 24px;
  margin-right: 3px;
  margin-top: -2px;
}
.prod_chilled.notchilled .prod_chilled_icon {
  background: url(../images/non-chilled.svg) no-repeat;
  min-width: 24px;
  min-height: 24px;
  margin-right: 0;
}
.prod_chilled span {
  vertical-align: middle;
  display: inline-block;
  width: 48px;
  -webkit-transition: width 0.3s; /* Safari */
  transition: width 0.3s;
}
.prod_chilled.notchilled span {
  width: 0px;
  overflow: hidden;
}
.prod_chilled span {
  color: #aa00ff;
}
.prod_pic {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 25px;
  /*	position: relative;
*/
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  overflow: hidden;
  height: 153px;
}
.no-image.prod_pic {
  background: url(../images/product-default.jpg) center center no-repeat;
  background-size: 100%;
}
.wishlist_discount {
  border-radius: 4px;
  height: 100%;
  left: 30px;
  margin-left: -100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 1;
}
.wishlist {
  background: rgba(178, 25, 255, 0.95) none repeat scroll 0 0;
  border-radius: 4px;
  color: #ffffff;
  float: left;
  font-size: 13px;
  height: 30px;
  line-height: 0;
  min-width: 100%;
  padding-left: 23px;
  padding-right: 5px;
  padding-top: 15px;
  position: relative;
  left: 7px;
}
.discount {
  background-color: rgba(255, 196, 18, 0.9);
  border-radius: 4px;
  color: #564111;
  float: left;
  font-size: 12px;
  line-height: 0;
  min-width: 100%;
  padding-left: 30px;
  padding-right: 5px;
  padding-top: 14px;
  position: relative;
  word-wrap: break-word;
  min-height: 30px;
}
.no-image.prod_pic img {
  /*display: none;*/
}
.prod_pic img {
  display: inline-block;
  max-height: 100%;
  /*max-width: 160px;*/
}
.prod_desc {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #37474f;
  text-align: left;
  line-height: 18px;
  /*margin-bottom: 20px;
	min-height: 38px;*/
  height: 72px;
  overflow: hidden;
}
.prod_price {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #737e83;
  text-decoration: line-through;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 5px;
  min-height: 20px;
}
.prod_discout_price {
  width: 100%;
  float: left;
  font-size: 18px;
  color: #37474f;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  margin-bottom: 5px;
}
.prod_addtocart {
  width: 100%;
  float: left;
  text-align: center;
}
.prod_addtocart img {
  display: inline-block;
  cursor: pointer;
}
/*------owl-carousel-ends-----------*/

.right_discount {
  width: 100%;
  float: left;
  background: #8d00d4;
  padding: 20px 10px;
  border-radius: 5px;
}
.huge {
  width: 100%;
  float: left;
  color: #ffc412;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
}
.hugediscou {
  width: 100%;
  float: left;
  color: #ffc412;
  font-size: 40px;
  text-align: center;
  font-family: "sugarbushregular";
  line-height: 30px;
}
.hd_des {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.recentorder {
  width: 100%;
  float: left;
  background: #ffffff;
  border: 1px solid #c8cbcc;
  border-top: none;
  border-radius: 5px;
  padding: 15px 0px;
  /*margin-top: 11px;*/
  overflow: hidden;
  position: relative;
}
.recentorder_title {
  width: 100%;
  float: left;
  color: #010101;
  padding: 0 14px;
  margin-bottom: 7px;
}
.ro_items {
  width: 100%;
  float: left;
  margin: 12px 0px;
  padding: 5px 10px;
  position: relative;
}
.ro_pic {
  width: 44px;
  float: left;
}
.ro_desc {
  width: 67%;
  padding: 0 7px;
  float: right;
  font-size: 12px;
  color: #000000;
  text-align: left;
}
.chk_unchk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: none;
}
.ro_items:hover .chk_unchk {
  display: block;
}
.ro_items.itemselect {
  background-color: #cdcdcd;
}
.modifyscroll {
  height: 333px;
  overflow: hidden;
}
.ro_items.itemselect .chk_unchk {
  display: block;
}
/*-------------checkbox-Modified---------*/
.modcheck {
  display: none;
}
.modcheck + label {
  vertical-align: middle;
  cursor: pointer;
}
.modcheck + label span {
  background: rgba(0, 0, 0, 0) url(../images/checkbox.png) no-repeat scroll 0px
    0px;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 16px;
  margin: 20px 0 0 23px;
  vertical-align: middle;
  width: 16px;
}
.modcheck:checked + label span {
  background: rgba(0, 0, 0, 0) url(../images/checkbox_chkd.png) no-repeat scroll
    0 0;
}
/*-------------checkbox-Modified-ends---------*/

/*-------------footer---------*/
.footer1_title {
  float: left;
  width: 100%;
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  color: #7f7f81;
  text-transform: uppercase;
  margin-bottom: 27px;
  line-height: 16px;
  margin-top: 10px;
  margin-top: 23px;
  min-height: 30px;
}
.footer1_subtitle {
  float: left;
  width: 100%;
}
.footer1_subtitle ul {
  padding-left: 0;
  list-style: none;
}
.footer1_subtitle ul li {
  padding-bottom: 14px;
}
.footer1_subtitle ul li a {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}
.footer1 {
  padding-top: 77px;
}
.footer1_pannel1cvr {
  padding-right: 0;
  width: 43%;
  padding-left: 15px;
}
.footer1_pannel2cvr {
  padding-right: 0;
  width: 22%;
}
.footer1_pannel3cvr {
  padding-right: 0;
  width: 21%;
}
.footer1_pannel4cvr {
  padding-right: 0;
  width: 14%;
  float: right;
}
.footer1_custmertext {
  color: #fff;
  float: left;
  font-family: "Work Sans", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  margin: 29px 0 28px;
  width: 100%;
}
.footer1_custmerbtmtxt {
  color: #fff;
  float: left;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
  width: 72%;
}
.footer1_sociallink {
  float: left;
  margin: 42px 0 38px;
  width: 100%;
}
.footer1_sociallink img {
  display: inline;
  /*margin-right: 16px;*/
}
.footer1_privcvr {
  float: left;
  width: 100%;
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  color: #fff;
  text-transform: uppercase;
}
.footer1_privcytext {
  float: left;
  width: 29%;
}
.footer2_btmcvr {
  /*	border-top: 1px solid #3a3b40;
*/
  float: left;
  width: 100%; /*	margin-top: 59px;
*/
}
.footer2_madetxt {
  float: left;
  text-align: center;
  width: 100%;
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  color: #fff;
  font-size: 42px;
  margin: 61px 0 34px;
  line-height: 33px;
}
.footer2_madetxt img {
  display: inline-block;
}
.footer2_copytxt {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 82px;
}
.socilblok_folowtxt {
  float: left;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  color: #fff;
  padding-top: 5px;
}
.socilblok_twtr {
  color: #fff;
  float: left;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  margin-left: 78px;
  width: 13%;
}
.socilblok_folowtxt {
  float: left;
  text-align: center;
  width: 49%;
}
.socilblok_folowtxt1 {
  float: right;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
}
.socilblok_fb {
  float: left;
  color: #fff;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
}
.social_block {
  padding: 41px 0 42px;
}
.socilblok_fb img {
  float: left;
}
.socilblok_twtr img {
  float: left;
}
.socilblok_twtr img {
  float: left;
  padding-top: 2px;
}
.socilblok_fb > a > span {
  float: left;
  padding-left: 21px;
  padding-top: 5px;
}
.socilblok_twtr > a > span {
  float: left;
  padding-left: 21px;
  padding-top: 5px;
}
/*-------------footer-ENDS---------*/

.offers {
  width: 66%;
  margin: 0 auto;
  overflow: hidden;
}
.offrates {
  width: 49%;
  float: left;
  background: url(../images/img01.png) no-repeat center center;
  height: 154px;
  padding: 40px 0;
}
.happy_hours {
  width: 49%;
  float: right;
  background: url(../images/img02.png) no-repeat center center;
  height: 154px;
}
.offrates_txt {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 50px;
  /*padding-top: 44px;*/
}
.firstorder {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
}
.happy_hours_txt {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 120px;
  color: #71524b;
  line-height: 90px;
  padding-top: 28px;
  font-family: "sugarbushregular";
}
.yourdoor {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 11px;
  color: #71524b;
  text-transform: uppercase;
}
.single_slider {
  width: 66%;
  margin: 20px auto 45px;
  overflow: hidden;
  background: url(../images/bg_grey.png) no-repeat center center / cover; /*min-height: 335px;*/
}
.slido_left {
  float: left;
  height: 100%;
  margin-top: 0;
  position: absolute;
  width: 30%;
}
.innertextmiddle {
  display: table-cell;
  vertical-align: middle;
}
.slido_pic {
  border-radius: 50%;
  float: none;
  height: 113px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  width: 113px;
}
.slido_pic img {
  display: inline-block;
  width: 100%;
}
.slido_txt {
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin: auto;
  margin-top: 10px;
}
.slido_right {
  float: right;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  height: 300px;
  padding: 50px 53px 50px;
  position: relative;
  right: 0;
  width: 70%;
  word-wrap: break-word;
  display: table;
}
.slido-rightinnertext {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.brands {
  margin-bottom: 45px;
}
.brands img {
  display: inline-block;
  max-height: 100px;
}
.brands .text-center {
  display: table-cell;
  float: none;
  vertical-align: middle;
  max-width: 192px;
}
.brands .owl-carousel .owl-item {
  display: table-cell;
  float: none;
  max-width: 192px;
  vertical-align: middle;
}
.leftphone {
  max-width: 362px;
  margin-top: 47px;
}
.socio_btns {
  margin-top: 122px;
  width: 100%;
  float: left;
}
.socio_btns img {
  margin-bottom: 25px;
}
.orderbooz {
  width: 100%;
  float: left;
  color: #757575;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  margin-top: 90px;
}
.onthego {
  width: 100%;
  float: left;
  font-size: 120px;
  text-align: center;
  font-family: "sugarbushregular";
  color: #71524b;
  line-height: 100px;
  word-spacing: -25px;
}
.booz_desc {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #757575;
  text-align: center;
  margin-top: 25px;
  padding: 0 30px;
}
.recentorder_buttons {
  width: 100%;
  float: left;
  padding: 10px;
  margin-bottom: 20px;
}
.addbtn {
  width: 53px;
  float: left;
  cursor: pointer;
}
.repeatbtn {
  width: 81px;
  float: right;
  cursor: pointer;
}
.viewbtns {
  width: 81px;
  float: left;
  cursor: pointer;
}
.orderhistorytext {
  width: 100%;
  float: left;
  color: #888888;
  font-size: 12px;
  padding: 5px 15px;
}
/*------------------Modalcss-register-login----------------------------------*/

.modal-content.alcohalregiterconntcvr {
  float: left;
  width: 100%;
  border-radius: 7px;
}
.modal-header.alcohalhdr {
  float: left;
  width: 100%;
  border-bottom: 0px;
  padding: 5px 15px 0;
}
.modal-body.alcohabody {
  float: left;
  padding-top: 0;
  width: 100%;
}
.modal-footer.alcohalfotter {
  background: #efefef none repeat scroll 0 0;
  float: left;
  width: 100%;
  text-align: center;
  padding: 32px 15px 36px;
}
.alcohal_regisemailcvr {
  margin: 0 auto;
  width: 73%;
}
.registeremailcvr {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.registeremailcvr label {
  float: left;
  font-size: 14px;
  color: #222222;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  width: 100%;
}
.alcohalrestertextbox {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  min-height: 50px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  color: #2c2011;
}
.alcohalhdr button.close {
  font-size: 44px;
  font-weight: 200;
  line-height: 39px;
}
.alcohal_regissigntxt {
  float: left;
  text-align: center;
  width: 100%;
  font-size: 24px;
  color: #454545;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 16px;
  line-height: 25px;
}
.modal-dialog.alcohal_registermain {
  width: 522px;
}
.register_checktext {
  float: left;
  width: 93%;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  color: #454545;
  line-height: 18px;
  margin-top: -5px;
}
.register_checktext a {
  color: #454545;
  text-decoration: underline;
}
.register_check {
  float: left;
}
.alcohalfootersign {
  font-size: 20px;
  color: #fff;
  background: #aa00ff;
  border: none;
  padding: 10px 49px;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
}
.alcohalrestertextbox:hover,
.alcohalrestertextbox:focus {
  border: 1px solid #8d00d4;
  box-shadow: 0 0 3px #8d00d4;
}
.alcohalfootersign.alcohal_fotrloginbox {
  float: right;
}
.alcohallogin_forgettxt {
  float: left;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  color: #454545;
  padding-top: 6px;
}
.alcohallogin_forgettxt a {
  color: #454545;
  text-decoration: none;
}
.registeremailcvr .modcheck + label span {
  margin: 1px 0 0 0;
}
/*orderhistroy*/

.modal-content.alcohal_orderhistorycvr {
  float: left;
  width: 100%;
}
.modal-header.alcohal_orderhistoryheader {
  float: left;
  width: 100%;
  background: #ffc412;
  padding: 14px 15px 13px;
}
.modal-body.alcohal_orderhistorybody {
  float: left;
  width: 100%;
  padding-bottom: 27px;
  padding-top: 27px;
  min-height: 263px;
  position: relative;
}
.modal-footer.alcohal_orderhistoryfotter {
  float: left;
  width: 100%;
  background: #efefef;
  padding-top: 11px;
  padding-right: 18px;
}
.alcohalorderhisttitle {
  font-size: 28px;
  text-align: center;
  float: left;
  width: 100%;
  color: #564111;
}
.modal-dialog.alcohal_orderhistory {
  width: 682px;
}
.alcohal_orderhistorytxtcvr {
  margin: 0 auto;
  /*width: 78%;*/
  max-width: 490px;
}
.alcohal_orderhistorybodytxt {
  margin: 0 auto;
  text-align: center;
  width: 77%;
  font-size: 14px;
  line-height: 23px;
  color: #666666;
}
.alcohal_orderhistorybodytxtclick {
  float: left;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #666666;
  margin-top: 3px;
}
.alcohal_hisryorderboxcvr {
  float: left;
  width: 100%;
  margin-top: 41px;
}

.alcohal_hisryorderboxcvr .alcohal_hisryorderboxcvr1:nth-child(even) {
  margin-right: 0px;
}

.alcohal_hisryorderboxcvr1 {
  display: inline-block;
  border: 1px solid #d7d7d7;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 18px;
  width: 236px;
  margin-right: 15px;
  cursor: pointer;
}
.alcohal_hisryorderordertxt {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #37474f;
  font-weight: 600;
}
.alcohal_hisryorderitemtext {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #37474f;
  font-weight: 600;
}
.alcohal_hisryorderdelitext {
  float: left;
  width: 100%;
  font-size: 11px;
  color: #888888;
}
.order_rightbox {
  float: right;
}
.alcohal_hisryorderboxcvr1:hover {
  box-shadow: 0 0 2px #8d00d4;
  border: 1px solid #9d9d9d;
}
/*.alcohal_hisryorderboxcvr1:nth-child(2n+2) {
	margin-right: 0;
}*/
.alcohalfooter_shopordershopbtn {
  font-size: 12px;
  color: #fff;
  background: #aa00ff;
  border: none;
  padding: 11px 13px 11px 23px;
  border-radius: 5px;
  text-transform: uppercase;
  float: right;
  font-weight: 500;
}
.alcohalfooter_shopordershopbtn > span {
  margin-left: 8px;
}
.modal-body.alcohal_reapeatorederbody {
  float: left;
  padding-bottom: 74px;
  padding-top: 30px;
  width: 100%;
}
.alcohal_reapeatorederbodycvr {
  margin: 0 auto;
  width: 70%;
}
.alcohal_reapeatorederbodytitle {
  float: left;
  width: 100%;
  font-size: 30px;
  color: #666666;
  font-weight: 400;
  text-align: center;
}
.alcohalfooter_repeatorderbtn {
  font-size: 14px;
  color: #fff;
  background: #aa00ff;
  border: none;
  padding: 10px 37px;
  border-radius: 5px;
  text-transform: uppercase;
  float: right;
  font-weight: 500;
}
.alcohal_reapeatorederbtnsocvercvr {
  margin: 0 auto;
  padding-bottom: 30px;
  width: 337px;
  overflow: hidden;
}
.alcohal_reapeatorenothnxtxt {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  float: right;
  margin-right: 35px;
  padding-top: 10px;
}
.alcohal_repeatorderbodytxt {
  float: left;
  text-align: center;
  width: 100%;
  font-size: 14px;
  line-height: 23px;
  color: #666666;
  margin-top: 19px;
  margin-bottom: 30px;
}
.alcohal_orderhistory2fotter {
  color: #666666;
  float: left;
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  text-transform: uppercase;
  width: 72%;
}
.modal-dialog.alcohal_orderhistory2 {
  width: 802px;
}
.alcohal_orderhistory2body {
  float: left;
  width: 100%;
  padding: 20px 15px;
}
.alcohal_orderhistory2bodytext1 {
  float: left;
  width: 100%;
  color: #37474f;
  font-size: 16px;
}
.alcohal_orderhistory2bodytext2 {
  float: left;
  width: 100%;
  padding: 2px 0px;
  color: #888888;
  font-size: 11px;
}
.alcohal_ordehisty2selctboxcvr {
  float: left;
  width: 100%;
  margin: 15px 0 3px;
  padding-left: 8px;
}
.orderhistysect_check {
  display: none;
}
.orderhistysect_check + label {
  color: #222222;
  font-size: 12px;
  line-height: 29px;
  vertical-align: middle;
  font-weight: 400;
}
.orderhistysect_check + label span {
  background: rgba(0, 0, 0, 0) url(../images/ordrhisty2_uncheckpic.png)
    no-repeat scroll 0 0;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 22px;
  margin: 6px 3px 0 0;
  vertical-align: middle;
  width: 22px;
}
.orderhistysect_check:checked + label span {
  background: rgba(0, 0, 0, 0) url(../images/ordrhisty2_checkpic.png) no-repeat
    scroll 0 0;
}
.alcohal_ordehisty2selctblcvr {
  float: left;
  width: 100%;
}
.orhistry2_td2procdtcvr {
  float: left;
  width: 100%;
}
.orhistry2_td2procdtcvr > span {
  float: left;
  margin-right: 15px;
  width: 70px;
  height: 70px;
}
.orhistry2_td2proctext2 {
  float: left;
  font-size: 12px;
  color: #7a7a7a;
  width: 100%;
}
.orhistry2_td2proctext1 {
  float: left;
  font-size: 15px;
  color: #222222;
  font-weight: 500;
  width: 100%;
}
.modal-footer.alcohal_orderhistory2fotter {
  background: #efefef none repeat scroll 0 0;
  float: left;
  padding-right: 18px;
  padding-top: 11px;
  width: 100%;
  margin-top: 21px;
}
.orderhistrychilcvr {
  float: left;
}
.orderhistrychilcvr span1 {
  float: left;
}
.orderhistrychilcvr > span {
  float: left;
  font-size: 11px;
  margin-left: 7px;
  padding-top: 5px;
  color: #aa00ff;
  font-weight: 600;
}
.orderhistry2price {
  float: right;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.alcohal_ordehisty2selctblcvr .table {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.alcohal_ordehisty2selctblcvr .table tbody tr {
  padding: 20px 0;
  border-bottom: 1px solid #e2e2e2;
}
.alcohal_ordehisty2selctblcvr .table > thead > tr > th {
  border-bottom: none;
  padding: 0;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td,
.alcohal_ordehisty2selctblcvr .table > tbody > tr > th,
.alcohal_ordehisty2selctblcvr .table > tfoot > tr > td,
.alcohal_ordehisty2selctblcvr .table > tfoot > tr > th,
.alcohal_ordehisty2selctblcvr .table > thead > tr > td {
  padding: 35px 10px;
  vertical-align: middle;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td:nth-child(1) {
  padding-left: 8px;
  width: 7%;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td:nth-child(2) {
  width: 48%;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td:nth-child(3) {
  width: 16%;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td:nth-child(4) {
  width: 16%;
}
.alcohal_ordehisty2selctblcvr .table > tbody > tr > td:nth-child(5) {
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding: 10px 11px;
  background-color: #e2e2e2;
  border-color: #d7d7d7;
  color: #333;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default.focus:active,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  background-color: #aa00ff;
  border-color: #aa00ff;
  color: #333;
}

.bootstrap-touchspin .input-group-btn-vertical {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 1%;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  margin-left: -1px;
  max-width: 100%;
  padding: 8px 10px;
  position: relative;
  width: 100%;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  font-size: 9px;
  font-weight: normal;
  left: 5px;
  position: absolute;
  top: 3px;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px;
  margin-top: 0px;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  margin-left: -1px;
  max-width: 100%;
  padding: 9px 10px;
  position: relative;
  width: 100%;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  font-size: 9px;
  font-weight: normal;
  left: 5px;
  position: absolute;
  top: 3px;
}
.alcohocal_ordrhistryincrmnetdrcrmnt2cvr {
  float: left;
  width: 63%;
}
/*------------------Modalcss-register-login-ends----------------------------------*/

.navbar-fixed-top {
  background: #ffc412;
}
.navbar-fixed-top.navbar-shrink {
  box-shadow: 0px 1px 2px #000;
}
.navbar-fixed-top.navbar-shrink .searchtop {
  top: 25px;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.logo_cover {
  /*position:relative;*/
  min-height: 85px;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.navbar-fixed-top.navbar-shrink .logo_cover {
  min-height: 60px;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.logolarge {
  left: 46%;
  position: absolute;
  top: 5px;
  transition: all 1s ease-in-out;
  width: 171px;
  opacity: 1;
}
.text-center .logosmall {
  width: 51px;
  position: absolute;
  left: 50%;
  top: 20px;
  opacity: 0;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.navbar-fixed-top.navbar-shrink .logolarge {
  width: 171px;
  opacity: 0;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  /*display: inline-block;*/
}
.navbar-fixed-top.navbar-shrink .text-center .logosmall {
  opacity: 1;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.navbar-fixed-top.navbar-shrink .callshape {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  padding-top: 0;
  transition: all 0.5s ease 0s;
}
.callshape img {
  max-width: auto;
}
.navbar-fixed-top.navbar-shrink .homecallus {
  margin-top: 8px;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.navbar-fixed-top.navbar-shrink .signuplogin {
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.navbar-shrink .signuplogin a,
.navbar-shrink .signuplogin a:hover,
.navbar-shrink .signuplogin a:focus {
  padding: 25px 15px; /*-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;*/
}
.navbar-shrink .cart_icon {
  /*background-position: -338px 25px !important;*/
  min-height: 69px; /*-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;*/
}
.navbar-shrink .cart_icon:hover {
  /*background-position: -338px 25px !important;*/
  min-height: 69px; /*-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;*/
}
.fullwidth.settop {
  margin-top: 80px;
}
.bootstrap-touchspin .form-control {
  font-size: 20px;
  padding: 5px 10px;
}
.cartbtn {
  width: 158px;
  height: 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #aa00ff;
  background: #aa00ff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  color: #ffffff;
  opacity: 0.9;
}

.cartbtn:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  opacity: 1;
}

.addcart {
  width: 100%;
  float: left;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: #aa00ff;
}
.addcart span {
  background: url(../images/add-cart-new.svg) no-repeat 0px 2px;
  width: 19px;
  height: 20px;
  display: inline-block;
  float: left;
  margin: 4px 3px 0 12px;
}
.addmore {
  width: 100%;
  float: left;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: opacity 0.2s ease;
  opacity: 0;
}

.fadeshow {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.addmanual {
  width: 100%;
  float: left;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.horizontal .addmore-count {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  font-size: 15px;
  padding: 2px;
  text-align: center;
  width: 32px;
  height: 32px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}
.relat {
  width: 100%;
  float: left;
  border-radius: 5px;
  position: relative;
}
.addmanual input {
  width: 0%;
  float: left;
  height: 32px;
  line-height: 32px;
  border: none;
  outline: none;
  border-radius: 4px 0 0 4px;
  padding: 5px 5px 5px 26px;
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  color: #37474f;
  position: relative;
  border-left: 1px solid #bdc2c5;
  border-top: 1px solid #bdc2c5;
  border-bottom: 1px solid #bdc2c5;
  margin: -1px 0 -1px -1px;
  transition: padding-left linear 0.2s;
}
.addmanual .addbuttton {
  width: 100%;
  float: right;
  border-radius: 5px;
  height: 30px;
  background: transparent;
  line-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
}
/*------Product Page--------*/
.innerpagemargintop {
  margin-top: 95px;
}
.single_slider_product {
  width: 100%;
  float: left;
  background: #ffffff;
  border: 1px solid #dfe2e4;
  border-top: none;
  border-radius: 5px;
  padding: 20px 21px 32px;
  position: relative;
  margin-top: 0;
  margin-bottom: 14px;
}
.productinner_leftslide {
  float: left;
  width: 50%;
}

.productinner_rightslide {
  float: right;
  width: 44%;
  text-align: right;
  padding-top: 9%;
  height: 400px;
}
.featured_product {
  float: left;
  background: #ebeef0;
  font-size: 10px;
  color: #7c7b7d;
  font-weight: 400;
  min-width: 118px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  border-radius: 2px;
  text-transform: uppercase;
  margin-right: 10px;
}
.productslide_heading {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 28px;
  color: #656565;
  margin-top: 9px;
  margin-bottom: 3px;
  line-height: 36px;
}
.productslide_normaltext {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  color: #222122;
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
}
.productslide_actualprice {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  color: #222122;
  margin-top: 29px;
  text-decoration: line-through;
}
.productslide_discountprice {
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #222122;
  margin-top: 20px;
}
.productslide_addbutton {
  width: 100%;
  float: left;
  /*margin-top: 15px;
	max-width: 160px;*/
}
.productslide_addbutton .addcart span {
  margin: 4px 5px 0 10px;
}
.product_addcart,
.product_addcart:hover {
  height: 40px;
  width: 172px;
  background: #aa00ff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 17px;
}

.single_slider_product .owl-prev{
  padding-left: 0px !important;
  color: #a0f !important;
}

.single_slider_product .owl-next{
  padding-right: 0px !important;
  color: #a0f !important;
}

.single_slider_product .owl-carousel{
  position: unset;
}

/* .single_slider_product .owl-carousel .owl-wrapper-outer{
  padding: 0 10px;
} */

.single_slider_product .owl-carousel .owl-wrapper-outer .owl-item {
  padding: 0 20px;
}

.single_slider_product .owl-controls {
  bottom: 6px;
  float: left;
  /*position: relative;*/
  right: 0;
  text-align: center;
  width: 100%;
}
.productcomboul {
  border-bottom: solid 1px #d9d9d9 !important;
  border-top: 1px solid #d9d9d9;
}
.productcomboul li {
  margin-bottom: 0 !important;
}
.comboproductitems {
  padding: 10px;

  display: inline-block;
  margin: 30px 0px 0px;
}
.comboproductitems:hover {
  box-shadow: 0 0 10px 5px #d6dce0;
  transition: all 0.5s ease 0s;
}
.comboproductitems .prod_desc {
  font-size: 13px;
}
.prodcombo_addtocartbutton {
  width: 100%;
  float: left;
  text-align: left;
}
.productsoldbt,
.productsoldbt:hover {
  background: #cdcdcd;
  width: 158px;
  font-size: 12px;
  font-weight: 500;
  color: #8f8f8f;
  height: 32px;
  text-align: center;
  text-transform: uppercase;
}
.productsoldbt img {
  float: left;
}
.comboproductitems_outofstock .prod_pic img {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.productsoldoutbkbtn,
.productsoldoutbkbtn:hover {
  position: absolute;
  line-height: 24px;
  width: 77px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background: #000;
  padding: 0;
  top: 38%;
  z-index: 1;
  border-radius: 4px;
  text-align: center;
}
.combo_sliderProduct {
  margin-bottom: 20px;
}
.dropdown.productdropdownbtn.open {
  background: #eff1f2 none repeat scroll 0 0;
  border: medium none;
  float: right;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  min-height: 42px;
  width: 180px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.dropdown.productdropdownbtn {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: none;
  float: right;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  min-height: 42px;
  width: 180px;
}
.productdropdownbtn .dropdown-toggle,
.productdropdownbtn .dropdown-toggle:hover,
.productdropdownbtn .dropdown-toggle:active,
.productdropdownbtn .dropdown-toggle:focus {
  width: 100%;
  float: left;
  padding: 0;
  border: 0;
  background: none;
  text-align: left;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: 400;
  height: 19px;
  position: relative;
  outline: none !important;
  box-shadow: none;
  top: 10px;
  padding-left: 10px;
}

.productdropdownbtn.open {
}

.productdropdownbtn.open .dropdown-toggle,
.productdropdownbtn.open .dropdown-toggle:hover,
.productdropdownbtn.open .dropdown-toggle:active,
.productdropdownbtn.open .dropdown-toggle:focus {
  color: #aa00ff;
}
.nav.nav-tabs.productcomboul {
  padding-left: 20px;
}
.productdropdownbtn .caret {
  background: url(../images/producticon2.png) no-repeat center center;
  border: 0;
  width: 10px;
  height: 6px;
  position: absolute;
  right: 15px;
  top: 8px;
  transform: rotate(0deg);
}
.productbeerfiltertopcover {
  float: left;
  width: 100%;
  padding: 0 22px;
}
.productbeerfiltertopcover a.active {
  color: #aa00ff;
  font-weight: 500;
}
.productbeerfiltertoptitle {
  width: 100%;
  float: left;
  font-size: 24px !important;
  font-weight: 400;
  color: #656565 !important;
  margin-bottom: 20px;
}
.productbeerfiltertopcover .row div {
  font-size: 13px;
  font-weight: 400;
  color: #656565;
  margin-bottom: 13px;
  min-height: 40px;
}
.combo_sliderProduct .tabbable .fullwidth {
  margin-top: 19px;
}
.addmore .bootstrap-touchspin {
  background: transparent;
  width: 52px;
  float: left;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}
.addmore .bootstrap-touchspin input {
  position: absolute;
  top: 30px;
  left: 11;
}
.addmorehere {
  background-color: #8c00d1;
  border: 1px solid #8c00d1;
  border-radius: 0 4px 4px 0;
  display: inline-block;
  float: right;
  margin: -1px -1px 0 0px;
  text-align: center;
  width: 82px;
}
.addmore .bootstrap-touchspin-down,
.addmore .bootstrap-touchspin-up {
  background-color: transparent;
  border-color: transparent;
  border: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  margin-top: -2px;
}
.addmore .bootstrap-touchspin-down:focus,
.addmore .bootstrap-touchspin-up:focus,
.addmore .bootstrap-touchspin-down:active,
.addmore .bootstrap-touchspin-up:active,
.addmore .bootstrap-touchspin-down:hover,
.addmore .bootstrap-touchspin-up:hover {
  outline: none;
  background: transparent;
  border: 0px;
  color: #ffc412;
  background-color: transparent;
  box-shadow: none;
}
.checkoutmiddleouter {
  padding: 0px 10px;
  width: 100%;
  float: left;
  transition: all 0.5s ease 0s;
}
.checkoutmiddlevlauediv {
  float: left;
  width: 70px;
}
.checkouter-td-right-text {
  width: 74%;
  float: left;
}
.orhistry2_td2proctext1 {
  width: 100%;
  float: left;
}
.checkoutmiddletable .orhistry2_td2proctext2 {
  float: none;
}
.checkoutmiddletable .orhistry2_td2procdtcvr > span {
  margin-top: 5px;
  display: inline-block;
  width: 60px;
  height: 70px;
}
.checkoutmiddletabledeleteimg,
.checkoutmiddletabledeleteimg:hover,
.checkoutmiddletabledeleteimg:focus {
  color: #979797;
  display: inline-block;
}
.orderhistrychilcvr-right {
  float: right;
}
.checkoutmiddletable .orderhistrychilcvr {
  float: right;
}
.checkbox-gray-top {
  background: #ebeef0;
  padding: 7px;
  text-align: center;
  display: inline-block;
  width: 100%;
}
.checkbox-gray-top-first {
  display: inline-block;
  vertical-align: middle;
}
.checkbox-gray-toptext {
  display: inline-block;
  font-size: 14px;
  color: #666666;
}
.checkbox-gray-top-first .switch-button-background {
  background: #ffc412;
  border-radius: 30px;
  margin: 1px 20px;
}
.checkbox-gray-top-first .switch-button-button {
  background: #fff;
  border: #564111 2px solid;
  width: 17px !important;
  top: 2px;
  height: 17px !important;
}
.checkoutmiddletable table td:not([class="childtd"]) {
  vertical-align: middle !important;
  padding: 20px 8px !important;
}
.checkboxtotaldiv {
  width: 100%;
  float: left;
}
.checkboxtotaldiv-top {
  width: 100%;
  float: left;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 20px 15px 0px;
}
.checkboxtotaldiv-middle {
  width: 100%;
  float: left;
  padding: 15px 25px 15px 0px;
}
.checkboxtotaldiv-title {
  width: 88%;
  text-align: right;
  float: left;
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}
.checkboxtotaldiv-text {
  text-align: right;
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  /*float:right;*/
  position: relative;
}

.checkboxtotaldiv-middle-first .checkboxtotaldiv-text {
  float: right;
}

.checkboxtotaldiv-middle-first .checkboxtotaldiv-title {
  width: 79%;
}

.checkboxtotaldiv-middle-first {
  width: 100%;
  float: left;
  margin: 5px 0px;
}
.checkboxtotaldiv-middle-first .checkboxtotaldiv-title,
.checkboxtotaldiv-middle-first .checkboxtotaldiv-text {
  font-size: 12px;
}
.checkboxtotaldiv-buttom {
  width: 100%;
  float: left;
  border-top: 1px solid #e2e2e2;
  padding: 15px 40px 15px 0px;
}
.checkboxtotaldiv-buttom .checkboxtotaldiv-title,
.checkboxtotaldiv-buttom .checkboxtotaldiv-text {
  font-size: 18px;
}
.checkoutproduct1 {
  width: 20%;
  float: left;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-radius: 0;
  color: #ffffff;
  float: left;
  text-align: center;
}
.checkoutproduct1 .prod_desc {
  font-size: 12px;
}
.checkoutproductbutton {
  background: #aa00ff;
  padding: 8px 10px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
}
.checkout-product {
  width: 100%;
  float: left;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #e2e2e2;
  /*border-top: 1px solid #e2e2e2;*/
}
.checkoutproduct1:last-child {
  margin-right: 0px;
}
.checkoutcongratulationouter {
  width: 100%;
  float: left;
  padding: 15px 0px;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}
.checkout-congratulation-title {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
}
.checkout-congratulation-text {
  width: 100%;
  float: left;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.checkouterwantbuttonouter {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.checkouterwantfirst {
  width: 100%;
  float: left;
}
.checkouterwantbutton {
  width: 280px;
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fefefe+0,f3f3f3+50,f3f3f3+55,e7e7e7+100 */
  background: #fefefe; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #fefefe 0%,
    #f3f3f3 50%,
    #f3f3f3 55%,
    #e7e7e7 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #fefefe 0%,
    #f3f3f3 50%,
    #f3f3f3 55%,
    #e7e7e7 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #fefefe 0%,
    #f3f3f3 50%,
    #f3f3f3 55%,
    #e7e7e7 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e7e7e7', GradientType=0 ); /* IE6-9 */
}
.checkouterwantbutton.selected {
  border: 1px solid #8d00d4;
  color: #8d00d4;
}
.checkouterwantfirst span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 12px;
  color: #666666;
}
.checkoutergitpackage {
  float: right;
  background: #ffc412 url(../images/giftimg.png) 15px 5px no-repeat;
  font-size: 14px;
  color: #564111;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 10px 20px 10px 70px;
}
.checkouterexprienceouter {
  border-bottom: 1px solid #e2e2e2;
  float: left;
  margin-bottom: 0;
  margin-top: 30px;
  padding-bottom: 10px;
  width: 100%;
}
.checkouterexpressdiv {
  float: left;
  width: 60%;
  margin-left: 15px;
}
.checkouterexpressdivleft {
  float: left;
}
.checkouterexpressdivleft .switch-button-background {
  margin-left: 0px;
}
.checkouterexpresstitle {
  font-size: 14px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 5px;
}
.checkouterexpresstext {
  font-size: 12px;
  color: #666666;
  font-weight: normal;
}
.checkouterexpresstext a {
  color: #666666;
  text-decoration: underline;
}
.checkouterlastdiv {
  float: left;
  width: 100%;
  padding: 30px 0px 20px;
}
.checkoutcheckoutnowbutton {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  min-width: 220px;
  vertical-align: middle;
}
.checkouterback {
  display: inline-block;
  vertical-align: middle;
  color: #666666;
  margin-right: 15px;
}
.checkouterbackouter {
  float: left;
  width: 100%;
  text-align: right;
  color: #666666;
  font-size: 14px;
  vertical-align: middle;
  /*margin-top: 50px; Commented by AP*/

  text-transform: uppercase;
}
.innerpagemargintop {
  margin-top: 110px;
}
.checkoutrighttext {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.checkoutrightpleasediv {
  width: 100%;
  float: left;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px;
  font-size: 11px;
  color: #7f7f7f;
  font-weight: 500;
  text-align: center;
  margin: 15px 0px 10px;
}
.checkoutrighbuttonouter {
  margin-bottom: 20px;
  width: 100%;
  float: left;
}
.checkoutrighbuttoncancel {
  width: 65px;
  float: left;
  background: #cdcdcd;
  padding: 7px 0px;
  text-align: center;
  color: #8f8f8f;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
}
.checkoutrighbuttonadd {
  width: 100%;
  /*float: right;*/
  float: none;
  background: #ffc412;
  padding: 7px 0px;
  text-align: center;
  color: #564111;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
}
.checkouter-rightsmalltext {
  color: rgba(255, 255, 255, 0.6);
  font-size: 9px;
  text-align: center;
}
.form-control:focus {
  /*box-shadow:none;
	border:none;*/
}
.prddetailtitle {
  width: 100%;
  float: left;
  font-size: 28px;
  color: #666;
  font-weight: 500;
  position: relative;
  z-index: 4;
}
.prddetailsubexttitle {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  position: relative;
}
.prddetailnormaltext {
  width: 100%;
  float: left;
  font-size: 13px;
  color: #666;
  font-weight: 500;
  text-align: justify;
  margin-top: 17px;
}
.prddetailchilledcover {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.prd_prodchilledPink {
  float: left;
  padding: 6px 7px;
  border: solid 1px #aa00ff;
  border-radius: 4px;
  margin-right: 5px;
  box-shadow: 0 0 1px #aa00ff;
}
.prd_prodchilledPink span {
  display: inline-block;
  float: right;
  color: #aa00ff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2px;
}
.prd_prodchilledgray {
  float: left;
  padding: 6px 7px;
  border: solid 1px #959595;
  border-radius: 4px;
}
.prd_prodchilledgray span {
  display: inline-block;
  float: right;
  color: #959595;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2px;
}
.prodchilled-btn {
  margin-right: 10px;
}
.prodchilled-btn,
.prodnonchilled-btn {
  float: left;
  padding: 6px 7px;
  border: solid 1px #959595;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 4;
}
.prodchilled-btn span,
.prodnonchilled-btn span {
  display: inline-block;
  float: right;
  color: #959595;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2px;
}
.prodchilled-btn .-icon,
.prodnonchilled-btn .-icon {
  background: url(../images/non-chilled.svg) no-repeat;
  float: right;
  margin-right: 6px;
  margin-top: -2px;
  min-height: 24px;
  min-width: 24px;
}
.prodchilled-btn.active .-icon,
.prodnonchilled-btn.active .-icon {
  background: url(../images/chilled.svg) no-repeat;
}
.prodchilled-btn.active,
.prodnonchilled-btn.active {
  border: solid 1px #aa00ff;
}
.prodchilled-btn.active span,
.prodnonchilled-btn.active span {
  color: #aa00ff;
}
.prod_chilled_icongray {
  background: url(../images/sprite.png) no-repeat scroll -410px 0;
  float: right;
  margin-right: 6px;
  margin-top: -2px;
  min-height: 24px;
  min-width: 24px;
}
.prddetailtotalamount {
  width: 100%;
  float: left;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -5px;
}
.prddetailpricedicnt {
  width: 100%;
  float: left;
  color: #222122;
  font-size: 30px;
  font-weight: 500;
  position: relative;
}
.prddetailpricedicnt span {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}
.prddetailinstock {
  width: 100%;
  float: left;
  color: #00a651;
  font-size: 14px;
  font-weight: 400;
  margin-top: 9px;
}
.prdquantitybulkcover {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pardqantitytexthead {
  width: 100%;
  float: none;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  margin-bottom: 6px;
}
.prdquantitybulkcover .checkoutmiddlevlauediv {
  margin-right: 30px;
}
.prdquantitybulkcoverbulk {
  width: auto;
  float: left;
}
.productdetailcovermainbgimg {
  width: 100%;
  float: left;
  background: #ffffff url(../images/productdetail1removethis.png) no-repeat
    98.5% 97%;
  border: 1px solid #dfe2e4;
  border-top: none;
  border-radius: 5px;
  padding: 20px 25px 32px;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
}
.productdetailcovermainbgimg .checkoutmiddlevlauediv {
  margin-top: 0px;
}
.combo_slider .tabsright .item {
  font-size: 0.9em;
  margin: 0 !important;
  padding: 0 !important;
  text-transform: capitalize;
  width: 100%;
}
.prdquantitybulkcoverbulkbox {
  width: 100%;
  float: left;
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  margin-top: -1px;
}
.prdquanbulkboxone {
  float: left;
  background: #d7d7d7;
  width: 65px;
  font-size: 10px;
  color: #222122;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  padding: 2px 6px;
  line-height: 17px;
}
.prdquanbulkboxtwo {
  float: left;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  padding: 0 9px;
  line-height: 19px;
}
.prdquanbulkboxone {
  float: left;
  background: #d7d7d7;
  width: auto;
  font-size: 10px;
  color: #222122;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  padding: 2px 6px;
  line-height: 17px;
}
.prdquanbulkboxtwo {
  float: left;
  width: auto;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  padding-left: 9px;
  line-height: 19px;
}
.prdquantitybulkcoverbulkbox > div {
  border-right: 1px solid #d7d7d7;
}
.prdquantitybulkcoverbulkbox > div:first-child,
.prdquantitybulkcoverbulkbox > div:last-child {
  border-right: 0px solid #d7d7d7;
}
.prdquanbulkboxthree {
  float: left;
  width: 53px;
  border-right: solid 1px #d7d7d7;
  border-left: solid 1px #d7d7d7;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  padding-left: 9px;
  line-height: 17px;
}
.prdquanbulkboxlast {
  float: left;
  width: 53px;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  padding-left: 9px;
  line-height: 17px;
}
.prdquanbulkActive {
  color: #aa00ff;
  border-bottom: solid 1px #aa00ff;
  margin-bottom: -1px;
}
.owluperpartcover {
  height: 415px;
  text-align: center;
  display: table;
  margin-bottom: 15px;
}
.owluperpartcover .owl-item {
  height: 415px;
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.owldownpartcover img {
  max-width: 100%;
  width: auto;
  height: 58px;
  border: solid 1px #ebeef0;
}
.comboproductdetailitems {
  margin-bottom: 30px;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 20%;
}
.comboproductdetailitems .prod_desc {
  font-size: 12px;
}
.productdetailtitle {
  width: 100%;
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #666666;
  padding-left: 14px;
}
.bootstrap-touchspin.vertical .addmore-count {
  /*border: 1px solid #cccccc;*/
  border-radius: 3px 0 0 3px !important;
  height: 39px;
  width: 100% !important;
  display: inline-block;
  text-align: center;
  font-size: 20px !important;
  padding: 7px 5px;
  color: #333;
  float: none !important;
}
.productdetailbrudcumcover {
  width: 100%;
  float: left;
  border: 1px solid #dfe2e4;
  border-top: none;
  border-radius: 5px;
  padding: 22px 25px 24px;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
  background: #fff;
  font-size: 13px;
  color: #7a7a7a;
  font-weight: 400;
}
.productdetailbrudcumcover a,
.productdetailbrudcumcover a:hover {
  font-size: 13px;
  color: #7a7a7a;
  font-weight: 500;
  text-decoration: none;
}
.productdetailbrudcumcover span {
  font-weight: 500;
  color: #222122;
}
.productdetailbrudcumcover img {
  margin-left: 5px;
  margin-right: 5px;
}
.navbar-fixed-top .container {
  position: relative;
  height: 100px;
}
.navbar-shrink .container {
  height: 70px;
}
.navbar-shrink .logoss {
  min-height: 70px;
  transition: all 0.3s ease 0s;
}
/*---------------inner-Header---------------------*/

/*.navbar-fixed-top {
	border-radius: 0;
}
.navbar-fixed-top {
	left: 0;
	position: fixed;
	right: 0;
	z-index: 1030;
}

.modal-open .navbar-fixed-top{
	width:calc(100% - 17px);
}

.navbar-fixed-top{
	background:#FFC412;
}


.navbar-fixed-top.navbar-shrink2 {
	box-shadow:0px 1px 2px #000;
}

.navbar-fixed-top.navbar-shrink2 .searchtop{
	top:25px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .logo_cover{
	min-height:60px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .logolarge{
	width:0px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .text-center .logosmall{
	width:51px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .callshape{
	display:none;
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .homecallus{
	margin-top:10px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .signuplogin{
	margin-top:23px;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s;  
	transition:all 0.5s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .container{
	height:70px;
	transition:all 0.3s ease 0s;
}

.navbar-fixed-top.navbar-shrink2 .logoss{
	height:70px;
	transition:all 0.3s ease 0s;
}
/*---------------inner-Header-ends---------------------*/

.steps_notify {
  width: 100%;
  float: left;
  position: relative;
  min-height: 60px;
  margin: 30px 0 42px;
}
.steps_notify .step_grid {
  width: 100%;
  float: left;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}
.steps_notify .step_grid img {
  display: inline-block;
}
.steps_notify .step_grid a {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
/*.step1 {
	left: 52px;
}
.step2 {
	left: 195px;
}
.step3 {
	left: 338px;
}
.step4 {
	left: 481px;
}
.step5 {
	right: 51px;
}*/
/*.steps_notify .step_grid a span {
	text-transform: uppercase;
	bottom: -23px;
	color: #888888;
	display: inline-block;
	font-size: 10px;
	left: -25px;
	position: absolute;
	text-align: center;
	width: 110px;
}*/

.steps-text {
  text-transform: uppercase;
  color: #888888;
  display: inline-block;
  font-size: 10px;
  text-align: center;
  width: 100%;
  margin-top: 12px;
}

/*#step1.active a:nth-child(1) span, #step2.active a:nth-child(2) span, #step3.active a:nth-child(3) span, #step4.active a:nth-child(4) span, #step5.active a:nth-child(5) span {
	color: #564111;
}*/

.steps-text.active {
  color: #564111;
}

.checkout_title {
  width: 100%;
  float: left;
  text-align: center;
  font-weight: 300;
  color: #666666;
  font-size: 48px;
  letter-spacing: -2.5px;
}
.congratulationpopup {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  box-shadow: 0 10px 30px 10px #bcc6d1;
  display: none;
  left: -120px;
  padding: 20px 0;
  position: absolute;
  top: 40px;
  width: 290px;
}
.congratulationpopup:before {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  float: left;
  height: 20px;
  left: 50%;
  position: absolute;
  top: -7px;
  transform: rotate(135deg);
  width: 20px;
}
.checkboxtotaldiv-text:hover .congratulationpopup {
  display: block;
}
.congratulationpopupimg {
  float: left;
  overflow: hidden;
}
/*.congratulationpopupimg img {
	margin-left: -6px;
}*/
.congratulationpopuptext {
  float: right;
  text-align: left;
  width: 70%;
  padding-right: 20px;
}
.congratulationpopuptext-title {
  font-size: 16px;
  color: #454545;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  font-weight: 500;
}
.congratulationpopuptext-text {
  font-size: 12px;
  color: #7a7a7a;
  width: 100%;
  float: left;
}

.dependencypopuptext {
  display: inline-block;
  text-align: justify;
  width: 100%;
}
.dependencypopuptext-title {
  font-size: 16px;
  color: #454545;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  font-weight: 500;
  padding-left: 60px;
  position: relative;
}

.dependencypopuptext-title img.message-type {
  position: absolute;
  left: 0;
  max-width: 55px;
  max-height: 55px;
}
.dependencypopuptext-text {
  font-size: 12px;
  color: #7a7a7a;
  width: 100%;
  float: left;
}

/*------------popupmodal------------*/
.popup1button {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #ffffff;
  float: right;
  font-size: 12px;
  font-weight: 600;
  min-width: 220px;
  padding: 11px 13px 11px 11px;
  text-transform: uppercase;
}
.popup-product-outer {
  float: left;
  padding: 20px 0;
  width: 100%;
}
.popup-product-desc {
  color: #37474f;
  float: left;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}
.popup-product-addcart {
  background: #aa00ff none repeat scroll 0 0;
  border: 1px solid #aa00ff;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  position: relative;
  text-transform: uppercase;
  width: 142px;
}
.popup-product-addcart span {
  background: rgba(0, 0, 0, 0) url(../images/sprite.png) no-repeat scroll -381px
    0;
  display: inline-block;
  float: left;
  height: 17px;
  margin: 0px 5px 0 10px;
  width: 19px;
}
.popup-product-discout-price {
  font-weight: 500;
  color: #37474f;
  float: left;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
.popup-prod_price {
  color: #737e83;
  float: left;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  text-decoration: line-through;
  text-transform: uppercase;
  width: 100%;
}
/*<!--
---------end------------->*/ /*--------checkoutsteps2---------*/
.step2div .steps_notify {
  margin: 27px 0 15px;
}
.chek_out2title_text {
  width: 100%;
  float: left;
  color: #666;
  font-size: 48px;
  text-align: center;
  margin-top: 6px;
  font-family: "Work Sans";
  text-transform: capitalize;
  font-weight: 300;
}
.shiping_add_box {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.cover_div_addsboxs {
  width: 100%;
  float: left;
  margin: 0 auto;
}

.addressbook-btmpannelcover.cartaddresses
  .addressbokk-trvaichacovr:nth-child(even) {
  margin-right: 0px;
}

.addressbook-btmpannelcover .addressbokk-trvaichacovr:nth-child(3n) {
  margin-right: 0px;
}

.addressbook-btmpannelcover .addressbokk-trvaichacovr {
  margin: 10px 17px 10px 0;
}

.addressbook-btmpannelcover.cartaddresses .addressbokk-trvaichacovr {
  margin: 10px 20px 10px 0;
}

.div_addsboxs:nth-child(odd) {
  width: 48%;
  float: left;
  border: 1px solid #ccc;
  padding: 19px;
  box-shadow: -1px 1px 7px 6px #fff;
  border-radius: 3px;
  margin-right: 2%;
  margin-bottom: 20px;
}
.div_addsboxs:nth-child(even) {
  width: 48%;
  float: left;
  border: 1px solid #ccc;
  padding: 19px;
  box-shadow: -1px 1px 7px 6px #fff;
  border-radius: 3px;
  margin-left: 2%;
  margin-bottom: 20px;
}
.div_addsboxs2 {
  width: 48%;
  float: right;
  border: 1px solid #ccc;
  padding: 19px;
  box-shadow: -1px 1px 7px 6px #fff;
  border-radius: 3px;
}
.div_addsboxs:hover,
.div_addsboxs:focus,
.div_addsboxs.active {
  box-shadow: -1px 1px 7px 6px #eed8f8;
  border: 1px solid #9d9d9d;
  border-radius: 3px;
}
.paymenttype {
  padding: 10px;
  border: 1px solid transparent;
  position: relative;
}
.paymenttype.active {
  box-shadow: -1px 1px 7px 6px #eed8f8;
  border: 1px solid #9d9d9d;
  border-radius: 3px;
}
.div_addsboxs2:hover,
.div_addsboxs2:focus {
  box-shadow: -1px 1px 7px 6px #eed8f8;
  border: 1px solid #9d9d9d;
  border-radius: 3px;
}
.addsboxs_innerdiv {
  width: 100%;
  float: left;
  position: relative;
}
.rgt_iconadd_div {
  width: 11%;
  float: left;
}
.rgt_iconadd_div img {
  cursor: pointer;
}
.lft_textadd_div {
  width: 85%;
  float: right;
}
.add_alltext {
  width: 73%;
  float: left;
  padding-left: 5px;
}
.edit_del_icondiv {
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
}
.div_addsboxs:hover .edit_del_icondiv,
.div_addsboxs:focus .edit_del_icondiv,
.div_addsboxs:active .edit_del_icondiv {
  display: block;
}
.div_addsboxs.ng-scope.active .edit_del_icondiv {
  display: block;
}
.edit_del_icondiv img {
  cursor: pointer;
}
.edit_del_icondiv span {
  float: right;
  height: 14px;
  margin-left: 10px;
  width: 14px;
}
.plac_divetx {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #222;
  font-family: "Work Sans";
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 8px;
}
.gry_text_div {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #666;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 19px;
}
.yelo_btn_div {
  width: 100%;
  float: left;
  text-align: center;
}
.yelo_btn {
  margin: 0 auto;
  padding: 6px 20px;
  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 400;
  color: #564111;
  background-color: #ffc412;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
}
.cover_rt_lftdiv {
  width: 100%;
  float: left;
  margin-bottom: 28px;
}
.add-newaddbox {
  margin-top: 23px;
  width: 48%;
  float: left;
  min-height: 143px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 23px;
}
.add-newaddbox img {
  cursor: pointer;
}
.add-newaddinner {
  width: 100%;
  float: left;
  text-align: center;
}
.pic-addnew_add {
  width: 100%;
  float: left;
}
.pic-addnew_text {
  width: 100%;
  float: left;
  margin-top: 11px;
  font-size: 14px;
  color: #666;
  font-family: "Work Sans";
  font-weight: 500;
  text-transform: uppercase;
}
.coverboxsegment_div {
  width: 100%;
  float: left;
  margin-bottom: 83px;
}
.specil_intertion_div {
  width: 100%;
  float: left;
}
.div_specil_intertion_title {
  width: 100%;
  float: left;
  font-size: 20px;
  font-family: "Work Sans";
  color: #222;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.cover_secilinform {
  width: 100%;
  float: left;
}
.alcohlinputext2 {
  float: left;
  width: 47.5%;
  margin-bottom: 18px;
}
.alcohlinputext2 > label {
  color: #222222;
  float: left;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1px;
  width: 100%;
}
.alcohlinputext2 span {
  color: #8d00d4;
}
.alcohalpopuptext2 {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  padding: 9px 10px;
  width: 100%;
  box-shadow: none;
}
.alcohlinputextarea12 {
  float: left;
  width: 82%;
  margin-bottom: 18px;
}
.alcohlinputextarea12 > label {
  color: #222222;
  float: left;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1px;
  width: 100%;
}
.alcohlinputextareaa2 {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  font-size: 13px;
  height: 81px;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}
.alcohlchekboxdiv12 {
  float: left;
  width: 82%;
}
.signup_check {
  display: none;
}
.signup_check + label {
  color: #222222;
  font-size: 12px;
  font-weight: normal;
  font-family: "Work Sans";
}
.signup_check + label span {
  background: rgba(0, 0, 0, 0) url("../images/chekblkpic.png") no-repeat scroll
    0 0;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 22px;
  margin: 0px 6px 0 0;
  vertical-align: middle;
  width: 22px;
}
.signup_check:checked + label span {
  background: rgba(0, 0, 0, 0) url("../images/checkout.png") no-repeat scroll 0
    0;
}
.gry_itlic_text {
  width: 93%;
  float: left;
  font-family: "Work Sans";
  font-size: 12px;
  color: #666;
  padding-left: 30px;
  font-style: italic;
}
/*--------checkoutsteps3---------*/
.checkoutstepthree_dayfordelivery {
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  text-align: center;
}
.checkoutstepthree_showdate {
  width: auto;
  display: inline-block;
  float: none;
  font-size: 24px;
  font-weight: 400;
  color: #222;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 55px;
  padding: 16px 20px 6px 22px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  position: relative;
}

.checkoutstepthree_showdate:hover {
  border: 1px solid #aa00ff;
  box-shadow: 0 0 3px #aa00ff;
}

.checkoutstepthree_showdate img {
  vertical-align: top;
  margin-top: 8px;
  margin-left: 24px;
}
/*--------checkoutsteps4---------*/
.reserv_deliry_text {
  width: 100%;
  float: left;
  color: #666;
  font-size: 48px;
  text-align: center;
  margin-top: 6px;
  font-family: "Work Sans";
  text-transform: capitalize;
  font-weight: 300;
}
.step4div .steps_notify {
  margin: 27px 0 15px;
}
.div_pleas_chose_time_text {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 36px;
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px;
  color: #666;
}
.cover_creadetcard_cashdiv {
  width: 100%;
  float: left;
  margin-top: 69px;
}
.cover_cashdiv_left {
  width: 33.33%;
  /*width: 50%;*/
  float: left;
  text-align: center;
}
.cover_creadetcard_right {
  width: 50%;
  float: right;
  text-align: center;
}
.pic_divcash {
  width: 100%;
  float: left;
  height: 149px;
  display: table;
  text-align: center;
}
.pic_creadetcard {
  float: right;
  height: 149px;
  text-align: center;
  width: 100%;
  display: table;
}
.verticetnter {
  display: table-cell;
  vertical-align: middle;
}
.verticetnter2 {
  display: table-cell;
  vertical-align: middle;
}
.divcash_text {
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
}
.creadetcard_text {
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
}
.checkouterbackouter2 {
  color: #666666;
  float: left;
  font-size: 14px;
  margin-top: 46px;
  text-align: right;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}
.checkouterback2 {
  color: #666666;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  text-transform: uppercase;
}
.checkouterback2 span {
  display: inline-block;
  line-height: 42px;
  margin-right: 5px;
}
.checkoutcheckoutnowbutton2 {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  min-width: 220px;
  padding: 8px 12px 8px 16px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
  font-weight: 500;
}
.checkoutcheckoutnowbutton2 span {
  float: right;
  margin-top: -2px;
}
.checkouterbackouter2 a:focus,
.checkouterbackouter2 a:hover {
  color: #666666;
  text-decoration: none;
}
.step4div .checkouterlastdiv {
  float: left;
  padding: 30px 0 9px;
  width: 100%;
}
/*--------checkoutsteps5---------*/
.checkoutstep5righttop {
  float: left;
  padding: 15px 0px 15px 0;
  width: 100%;
}
.checkoutstep5right-middle {
  float: left;
  padding: 15px 0;
  width: 100%;
}
.checkoutstep5right-middle-title {
  color: #222222;
  float: left;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  width: 72%;
}
.checkoutstep5right-middle-title span {
  width: 12px;
  display: inline-block;
  height: 12px;
  margin-left: 5px;
}
.checkoutstep5right-buttom {
  border-top: 1px solid #e2e2e2;
  float: left;
  padding: 15px 0;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.checkoutstep5-mid-top {
  width: 100%;
  float: left;
  margin-bottom: 33px;
  margin-top: 35px;
}
.checkoutstep5right-bottom-title {
  color: #222222;
  float: left;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  width: 60%;
}
.checkoutstep5right-bottom-text {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
}
.checkoutstep5-left-first {
  width: 100%;
  float: left;
  border-top: 1px solid #d9d9d9;
  padding: 14px 10px 13px;
}
.checkoutstep5-left-first-left {
  display: inline-block;
  vertical-align: middle;
  width: 92%;
}
.checkoutstep5-left-first-lefttext {
  width: 100%;
  float: left;
  color: #888888;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.checkoutstep5-left-first-lefttitle {
  width: 100%;
  float: left;
  color: #222222;
  font-size: 16px;
}
.checkoutstep5-left-edit {
  display: inline-block;
  vertical-align: middle;
}
.checkoutstep5-mid-left {
  width: 65%;
  float: left;
}
.checkoutstep5-mid-right {
  width: 35%;
  float: left;
}
.checkoutmiddletable {
  width: 100%;
  float: left;
}
.checkoutstep5-mid-button {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  min-width: 220px;
  padding: 6px 10px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  float: right;
}
.checkoutstep5-mid-button-outer {
  float: left;
  padding: 41px 0 26px;
  border-top: 1px solid #e2e2e2;
  width: 100%;
  text-align: right;
}
.checkoutmiddletable table {
  margin-bottom: 0px;
}
.checkoutstep5-left-first:last-child {
  border-bottom: 1px solid #e2e2e2;
}
.chekoutstep5-table-title {
  width: 100%;
  float: left;
  font-size: 20px;
  color: #222222;
  font-weight: 400;
  padding: 0px 9px 9px;
}
.checkoutstep5-mid-button i {
  font-size: 10px;
  color: #fff;
  margin-left: 10px;
  top: -1px;
}
.checkoutstep5right-middle-first {
  float: left;
  margin: 7px 0;
  width: 100%;
}
/*--------checkoutsteps_modal---------*/
.alcohal_addadressmain {
  margin: 0 auto;
  width: 960px;
}
.alcohal_addadressmaincvr {
  float: left;
  width: 100%;
}
.alcohal_addadress_header {
  background: #ffc412 none repeat scroll 0 0;
  float: left;
  padding: 12px 15px;
  width: 100%;
}
.alcohal_addadress_body {
  float: left;
  padding: 20px 15px;
  width: 100%;
}
.alcohal_addadressfooter {
  background: #efefef none repeat scroll 0 0;
  float: left;
  padding: 10px 15px;
  width: 100%;
}
.alcohal_addadress_headercurcle {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
}
.alcohal_addadress_headertitle {
  color: #564111;
  display: inline-block;
  font-size: 28px;
  text-align: center;
  vertical-align: middle;
}
.alcohal_addadress_headercurmain {
  text-align: center;
  width: 100%;
}
.alcohal_addadress_headercurclecvr {
  display: inline-block;
  text-align: center;
}
.alcohal_addadress_headercurcle {
  background: #564111 none repeat scroll 0 0;
  border-radius: 50%;
  color: #ffc412;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 27px;
  line-height: 26px;
  margin-top: 0;
  position: relative;
  text-align: center;
  top: 0px;
  vertical-align: middle;
  width: 27px;
}
.alcohlinputextcvrmain {
  float: left;
  width: 100%;
}
.alcohlinputextcvr {
  float: left;
  width: 100%;
  margin-bottom: 14px;
}
.alcohlinputext {
  float: left;
  width: 47.5%;
}
.alcohlinputrighttext {
  float: right;
  width: 47.5%;
}
.alcohlinputext > label {
  color: #222222;
  float: left;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1px;
}
.alcohlinputrighttext > label {
  color: #222222;
  float: left;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1px;
}
.alcohalpopuptext {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  padding: 9px 10px;
  font-size: 13px;
  color: #424242;
  width: 100%;
}
.alcohaladdadrebody {
  float: left;
  width: 100%;
  padding: 26px 22px 5px;
}
.alcohaladdadremapbody {
  float: left;
  width: 100%;
  padding: 0px 0px 0px 22px;
}
.alcohlinputext span {
  color: #8d00d4;
}
.alcohlinputrighttext span {
  color: #8d00d4;
}
.addrestopmar {
  margin-top: 10px;
}
.alcohlinputrighttext1 {
  float: right;
  width: 47.5%;
  margin-top: 22px;
}
.alcohlinputrighttext1 span1 {
  float: left;
  margin: 0 10px;
  color: #d7d7d7;
}
.addadressflortext {
  float: left;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 9px 10px;
}
.adresfloortxtcvr {
  float: left;
  width: 31%;
}
.adresfloortxtcvr > label {
  float: left;
  width: 100%;
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 12px;
  color: #222222;
}
.adressunit {
  float: left;
  width: 31%;
}
.adressunit > label {
  float: left;
  width: 100%;
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 12px;
  color: #222222;
}
.adresfloortxtcvrspce {
  float: left;
  width: 11%;
  text-align: center;
  margin-top: 33px;
  color: #d7d7d7;
}
.alcohlinputextarea1 {
  float: left;
  width: 70%;
}
.alcohlinputextarea1 > label {
  float: left;
  width: 100%;
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 12px;
  color: #222222;
}
.alcohlinputextareaa {
  float: left;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  height: 90px;
  resize: none;
  font-size: 13px;
}
.adresssvaebtn {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  min-width: 120px;
  text-align: left;
  padding: 11px 13px 11px 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.adresssvaebtn > span {
  float: right;
  margin-top: -1px;
  padding-left: 5px;
}
.alcohal_adress2fotter {
  color: #666666;
  float: left;
  font-size: 14px;
  line-height: 23px;
  margin-top: 9px;
  margin-right: 20px;
  text-transform: uppercase;
  /*width: 77%;*/
  font-weight: 500;
  cursor: pointer;
}
.alchoaladdnewadressbodytitile {
  float: left;
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  color: #222222;
}
.alcohalosavebntcvr {
  float: right;
}
.mapboxadresssvaebtn > span {
  float: right;
}
.mapboxadresssvaebtn {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  min-width: 221px;
  text-align: left;
  padding: 11px 13px 11px 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.mapboxbodycvr {
  float: left;
  width: 100%;
  padding: 0;
}
.alcohlinputextmapcvr {
  float: left;
  width: 100%;
  margin: 0;
}
.mapbboxcvr {
  float: right;
}
.mapbodylfetbodycvr {
  float: left;
  width: 100%;
}
.mapbodylfetcvr label {
  font-weight: 500;
  font-size: 12px;
  color: #424242;
  margin-bottom: 1px;
}
.mapbodylfetcvr {
  float: left;
  padding: 20px 10px 10px 15px;
  width: 51%;
}
.capitalize {
  text-transform: capitalize;
}
.username {
  max-width: 150px;
  min-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
}
/*My account page css*/
.creditable {
  border-bottom: 1px solid #e2e2e2;
  border-collapse: collapse;
  padding: 20px;
  width: 100%;
  margin-bottom: 57px;
}
.creditable caption {
  text-align: left;
}
.creditable th {
  border: none;
  padding: 0px 10px 10px;
  background: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
  font-family: "Work Sans";
  color: #434242;
  font-weight: normal;
}
.creditable td {
  border-top: 1px solid #e2e2e2;
  padding: 10px 11px 10px;
  color: #222222;
  font-family: "Work Sans";
}
.creditable tr th:first-child {
  width: 319px;
  padding: 0 0 10px 10px;
}
.creditable tr th:nth-child(4) {
  width: 268px;
}
.mainfirsttddiv {
  width: 319px;
  float: left;
}
.arowpicdiv {
  width: 33px;
  float: left;
  margin-left: 10px;
  padding-top: 3px;
}
.textfistrdivmain {
  padding-left: 10px;
  text-align: left;
  width: 85%;
  line-height: 22px;
}
.textboldtit {
  width: 100%;
  float: left;
  color: #222222;
  font-size: 16px;
  font-family: "Work Sans";
}
.textnormalit {
  width: 100%;
  float: left;
  color: #222222;
  font-size: 20px;
  font-family: "Work Sans";
}
.text_time_no {
  width: 100%;
  float: left;
  font-size: 11px;
  color: #666666;
  font-family: "Work Sans";
}
.cometdivtextpar {
  width: 268px;
  font-size: 11px;
  color: #666;
  font-family: "Work Sans";
  line-height: 19px;
}
.creditable tr td:first-child {
  width: 319px;
  padding-left: 0px;
  word-wrap: break-word;
}
.creditable tr td:nth-child(2) {
  width: 124px;
  vertical-align: text-top;
}
.creditable tr td:nth-child(3) {
  width: 124px;
  vertical-align: text-top;
}
.creditable tr td:nth-child(4) {
  width: 218px;
}
.titlecreidt {
  width: 100%;
  float: left;
  color: #666666;
  font-size: 28px;
  margin-bottom: 32px;
  font-family: "Work Sans";
  font-weight: 500;
}
.balancedetailsdiv {
  width: 100%;
  float: left;
  margin-bottom: 58px;
}
.balancedetailsdivinner {
  float: left;
  text-align: center;
  width: 53px;
  margin-right: 50px;
}
.amountdiv {
  width: 100%;
  float: left;
  text-align: left;
  font-size: 18px;
  color: #434242;
  font-family: "Work Sans";
  font-weight: 500;
}
.elventestgry {
  text-align: left;
  font-size: 11px;
  color: #434242;
  width: 100%;
  float: left;
  font-family: "Work Sans";
  font-weight: 500;
  line-height: 12px;
  margin-top: 1px;
}
.mainalcohdiv {
  width: 100%;
  float: left;
  background-color: #ebeef0;
}
.laeftdiv {
  width: 100%;
  float: left;
}
.laeftdivmain {
  width: 20%;
  float: left;
}
.rgrhtdiv-outer {
  width: 80%;
}
.rgrhtdiv {
  width: 100%;
  float: right;
  background-color: #fff;
  border-radius: 5px;
  padding: 21px 25px 33px 25px;
  border: 1px solid #dfe2e4;
  margin-bottom: 16px;
}
.pic_divinnertitle {
  width: 100%;
  float: left;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
  padding-bottom: 15px;
}
.pic_divinnertag {
  width: 14px;
  height: 13px;
  float: left;
}
.pic_divinnertext {
  float: left;
  padding-left: 20px;
  text-align: left;
  width: 92%;
}
.pic_divinnertextbold {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #363535;
  font-family: "Work Sans";
  font-weight: 600;
  text-transform: uppercase;
}
.pic_divinnertextnormal {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #363535;
  font-family: "Work Sans";
  font-weight: 500;
  margin: 8px 0 0;
}
.pic_divinnertextnormal a {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #363535;
  font-family: "Work Sans";
  font-weight: 500;
  margin: 0px 0 0;
}
.pic_divinnertextnormal a:focus,
.pic_divinnertextnormal a:hover,
.pic_divinnertextnormal > a.active,
.pic_divinnertextnormal > a:active {
  color: #aa00ff;
  cursor: pointer;
  text-decoration: none;
}
.pic_divinnertextnormal span {
  margin-left: 5px;
  border: 1px solid #ccc;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  color: #a5a5a5;
  font-size: 10px;
  font-family: "Work Sans";
}
.laeftdivmain_title {
  width: 100%;
  float: left;
  font-size: 18px;
  color: #69696a;
  font-family: "Work Sans";
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 15px;
}
.bordernoebtm {
  border-bottom: none;
}
.breadcrumdiv .breadcrumb {
  background-color: #fff;
  list-style: outside none none;
  margin-bottom: 17px;
  padding: 8px 0px;
  font-size: 13px;
  color: #7a7a7a;
  font-family: "Work Sans";
}
.breadcrumdiv .breadcrumb > li {
  display: inline-block;
  font-size: 13px;
  color: #7a7a7a;
  font-family: "Work Sans";
  font-weight: 400;
}
.breadcrumdiv a {
  color: #7a7a7a;
  text-decoration: none;
  font-family: "Work Sans";
  font-weight: 400;
}
.breadcrumdiv .breadcrumb > .active {
  color: #222122;
  font-size: 13px;
  font-family: "Work Sans";
  font-weight: 500;
}
.breadcrumdiv .breadcrumb > li + li::before {
  color: #cccccc;
  content: ">";
  padding: 0 5px;
}
.fig_txtup {
  float: left;
}
.balancetexxt {
  width: 63%;
  float: left;
  font-size: 14px;
}
.balancetexxt a,
.balancetexxt a:hover,
.balancetexxt a:focus {
  font-weight: 600;
  color: #424242;
  text-decoration: none;
}
.profile_form {
  width: 70%;
  float: left;
}
.profile_form .registeremailcvr {
  width: 50%;
  padding-right: 20px;
  float: left;
}
.profile_form .registeremailcvr .alcohalrestertextbox {
  min-height: 35px;
  font-size: 14px;
}
.profile_form .fullwidth .alcohalfootersign {
  font-size: 16px;
  padding: 10px 25px;
}
.balancedetailsdiv2 {
  float: left;
  margin-bottom: 35px;
  width: 100%;
}
.change_form {
  width: 43%;
  float: left;
}
.change_form .registeremailcvr {
  width: 100%;
  float: left;
}
.change_form .registeremailcvr .alcohalrestertextbox {
  min-height: 35px;
  font-size: 14px;
}
.change_form .fullwidth .alcohalfootersign {
  font-size: 16px;
  padding: 10px 25px;
}
.balancetexxt2 {
  float: left;
  font-size: 14px;
  width: 100%;
  margin-bottom: 25px;
}
.balancedetailsdiv2 {
  float: left;
  margin-bottom: 35px;
  width: 100%;
}
.change_form {
  width: 43%;
  float: left;
}
/**/

/*Error Classess*/
.has-error .help-block,
.has-error .help-inline,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f3565d;
}
.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  float: left;
}
.has-error input[type="text"] {
  border-color: #f13e64;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.signuplogin ul {
  padding-left: 25px;
  list-style: none;
}
.signuplogin ul li {
  float: right;
}
.profil-dropdown {
  padding-left: 0 !important;
  margin-top: -3px;
}
.profil-dropdown li {
  float: left;
  width: 100%;
}
.signuplogin .dropdown:hover a {
  /*background: #e1b11f none repeat scroll 0 0;*/
}
.profil-dropdown li a {
  float: left;
  width: 100%;
  background: #fff !important;
  padding: 10px 20px !important;
}
.profil-dropdown li a:hover {
  background: #ddd !important;
}
.profil-dropdown:before {
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  content: "";
  height: 0;
  left: 40%;
  position: absolute;
  top: -12px;
  width: 0;
}
.wishlist-text {
  float: left;
  font-size: 11px;
  width: 50%;
  color: #434242;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 60px;
}
.wishgaptop {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}
.wishlist-tbledate {
  font-size: 12px;
  color: #bbbbbb;
}
.wishlist-tbledate {
  color: #333;
  font-size: 12px;
  margin-bottom: 4px;
}
.wishlist-tblestock {
  color: #4cc185;
  font-weight: 600;
}
.wishlist-tbledatecover {
  float: right;
  padding-right: 15px;
}
.wishnext {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  text-align: left;
  text-transform: uppercase;
  width: 111px;
}
.wishprev span {
  font-size: 11px;
}
.wishnext span {
  float: right;
  font-size: 10px;
  margin-top: 3px;
}
.wishprev {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  color: #c8c8c8;
  float: left;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  text-align: left;
  text-transform: uppercase;
  width: 110px;
  font-family: "Work Sans";
}
.ordr-htry-orderpannelcover {
  float: left;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 0 15px 0;
}
.orderhstry-ordertxtcover {
  float: left;
  width: 55%;
}
.orderhstry-orderad {
  float: left;
  font-size: 16px;
  width: 100%;
  font-family: "Work Sans";
  color: #37474f;
  padding: 2px 0;
}
.orderhstry-orderderd {
  float: left;
  font-size: 11px;
  width: 100%;
  font-family: "Work Sans";
  color: #888888;
}
.orderhstry-orderstustscover {
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  float: left;
  line-height: 20px;
  min-height: 69px;
  padding: 0 20px;
  width: 25%;
}
.orderhstry-orderthnx-cover {
  float: left;
  width: 20%;
  padding-left: 25px;
  line-height: 22px;
}
.orderhstry-orderstuststext {
  float: left;
  font-size: 11px;
  width: 100%;
  font-family: "Work Sans";
  color: #888888;
  padding: 15px 0 0px;
  text-transform: uppercase;
}
.orderhstry-orderdelevrdsuccss {
  float: left;
  font-size: 13px;
  width: 100%;
  font-family: "Work Sans";
  color: #69bd44;
}
.wishnext span {
  text-align: right;
}
.wish-nextprvicvr {
  float: right;
  margin: 6px 0 0 0;
}
.wislistborbtm {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px !important;
}
.orderhstry-orderratingcover {
  float: left;
}
.orderhstry-orderaccpt {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #37474f;
  font-family: "Work Sans";
}
.savedcard-mastecardcover {
  float: left;
  width: 60%;
}
.savedcarmastercardtxt {
  color: #222222;
  float: left;
  font-size: 16px;
  width: 100%;
}
.savedcaraccnubmrtxt {
  color: #222222;
  float: left;
  font-size: 20px;
  width: 100%;
  line-height: 22px;
}
.savedcarvalidtxt {
  color: #666666;
  float: left;
  font-size: 11px;
  width: 100%;
}
.savedcardtablecover .table td {
  padding: 20px 8px !important;
  vertical-align: middle !important;
  border-top: none !important;
  border-bottom: 1px solid #ccc !important;
}
.checkoutmiddletabledeleteimg.editpic > span {
  padding: 0 13px;
}
.savedcardtablecover .table td:nth-child(2) {
  width: 410px;
}
.savedcardtablecover .table td:nth-child(1) {
  width: 360px;
}
.savedcardtablecover .table td:last-child {
  text-align: right !important;
}
.savedcardtablecover .table tr:last-child td {
  border-bottom: none !important;
}
.savedcardtablecover {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.savecardholdertext {
  color: #666666;
  font-size: 11px;
}
.savecardtravistext {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
.savecardholdercover {
  float: left;
  line-height: 16px;
  padding: 24px 0 0;
}
.saveaddnewcard {
  color: #646567;
  float: left;
  font-size: 14px;
  font-weight: 500;
  margin-top: 23px;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}
.addressbook-btmpannelcover {
  float: left;
  width: 100%;
  margin-bottom: 75px;
}
.addressbook-btmpannelcover .addressbook-btmpannelcover {
  margin-bottom: 0px;
}
.addressbokk-trvaichacovr {
  float: left;
  width: 40%;
}
.addressbokk-trvaichacovr {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  padding: 15px 25px;
  width: 32%;
  margin: 10px 20px 10px 0;
  min-height: 120px;
}
.addressbokk-addnewadresscovr {
  border: 2px dashed #d7d7d7;
  border-radius: 5px;
  float: left;
  padding: 14px 26px 0;
  width: 31%;
  margin: 10px 0px 10px 0;
  text-align: center;
}
.addressbok-travis {
  float: left;
  position: relative;
  width: 100%;
}
.addressbok-travis > span {
  float: left;
  position: absolute;
  left: 0px;
}
.addresseditdltpic {
  float: right;
  display: none;
}
.addrestravis-text {
  color: #222222;
  float: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 30px;
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addressbok-address {
  color: #666666;
  float: left;
  font-size: 12px;
  padding: 4px 5px 5px 36px;
  width: 100%;
  min-height: 66px;
}
.addresseditdltpic > a {
  color: #959595;
  padding-left: 10px;
}
.addnewadresstext {
  color: #646567;
  float: left;
  font-size: 14px;
  font-weight: 600;
  min-height: 39px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.addressbokk-addnewadresscovr > img {
  display: inline-block;
  text-align: center;
}
.adressmiddlepartcvr {
  float: left;
  padding: 0 20px;
  width: 35%;
}
.adressmiddlepartcvr2 {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  float: left;
  padding: 25px 26px;
  width: 100%;
}
.addressbokk-trvaichacovr:hover,
.adressmiddlepartcvr2:hover {
  border: 1px solid #d6ccdb;
  box-shadow: 0 0 2px #8d00d4;
}
.addressbokk-trvaichacovr:hover .addresseditdltpic,
.adressmiddlepartcvr2:hover .addresseditdltpic {
  display: block;
}
.currentorderpopup {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  left: calc(39% - 145px);
  position: absolute;
  top: 98%;
  width: 290px;
  z-index: 9000;
  padding: 0;
  border: none;
  -webkit-box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
  -moz-box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
  box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
  cursor: default;
}
.currentorderpopup:before {
  background: #aa00ff none repeat scroll 0 0;
  content: "";
  float: left;
  height: 18px;
  left: 50%;
  position: absolute;
  top: -9px;
  transform: rotate(135deg);
  width: 18px;
  border: none;
}
.productaddedpopup {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  left: calc(39% - 145px);
  position: absolute;
  top: 100%;
  width: 290px;
  z-index: 9009;
  padding: 0;
  border: none;
  -webkit-box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
  -moz-box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
  box-shadow: 0px 29px 60px 0px rgba(86, 111, 140, 0.6);
}
.productaddedpopup:before {
  background: #aa00ff none repeat scroll 0 0;
  content: "";
  float: left;
  height: 18px;
  left: 50%;
  position: absolute;
  top: -9px;
  transform: rotate(135deg);
  width: 18px;
  border: none;
}

#usermenuli .productaddedpopup {
  left: calc(43% - 145px);
}

.currentorderpopuppinkbox {
  float: left;
  width: 100%;
  min-height: 48px;
  background: #aa00ff;
  border-radius: 5px 5px 0 0;
  padding: 14px 8px 0 20px;
}
.currentorderpopuppinkbox.on-blank {
  text-align: center;
  color: #fff;
  border-radius: 5px;
}
.currentorderpopuppinkboxleft {
  float: left;
  width: 140px;
  font-size: 16px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  text-transform: none;
}
.currentorderpopuppinkboxright {
  float: right;
  width: 80px;
  height: 22px;
  font-size: 12px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  border: solid 1px #d580ff;
  border-radius: 4px;
  text-align: center;
}
.currentorderpopupul {
  padding: 0 !important;
  margin: 0;
  width: 100%;
  float: left;
  height: 200px;
}
.currentorderpopupul li {
  list-style: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  font-size: 12px;
  color: #37474f;
  font-family: "Work Sans", sans-serif;
  width: 100%;
  float: left;
  text-transform: none;
  font-weight: normal;
  height: auto !important;
}
.currentorderpopupul li:last-child {
  border-bottom: 0px;
  border-radius: 0 0 4px 4px;
}
.currentorderpopupulleft {
  /*width: 150px;*/
  width: 62.22%;
  float: left;
}
.currentorderpopupulcenter {
  /*width: 40px;*/
  width: 14.81%;
  text-align: right;
  float: left;
}
.currentorderpopupulright {
  /*width: 62px;*/
  width: 22.96%;
  text-align: right;
  float: left;
}
.navbar.navbar-default.navbar-fixed-top
  .signuplogin
  .dropdown:hover
  .cart_icon {
  /*background: #e1b11f url("../images/cart-icon-new.png") no-repeat scroll center 41px;*/
}
.navbar.navbar-default.navbar-fixed-top.navbar-shrink
  .signuplogin
  .dropdown:hover
  .cart_icon {
  /*background: #e1b11f url("../images/cart-icon-new.png") no-repeat scroll center 25px;*/
}
.additempopuppinkbox {
  background: #aa00ff url("../images/imgpsh_fullsize.png") no-repeat right
    center;
  border-radius: 5px 5px 0 0;
  float: left;
  min-height: 72px;
  padding: 17px 8px 0 20px;
  width: 100%;
}
.additempopuppinkboxleft {
  font-size: 30px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}
.additempopuppinkboxright {
  font-size: 14px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: normal;
  text-transform: none;
  padding-left: 50px;
  text-align: left;
}
.additempopuppinkboxrightinner {
  width: 100%;
  float: left;
  color: #ffc412;
  font-size: 12px;
  padding-top: 3px;
}
.additempopupwhitebox {
  float: left;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 14px;
}
.additempopupwhiteblacktext {
  width: 100%;
  font-size: 12px;
  color: #454545;
  font-family: "Work Sans", sans-serif;
  float: left;
  font-weight: normal;
  text-transform: none;
  text-align: center;
}
.additempopupwhitebarimgcover {
  float: left;
  width: 100%;
  padding-left: 20px;
  padding-right: 17px;
}
.additempopupwhitebarimgcover img {
  float: right;
}
.additempopupwhitebarcover {
  width: 86%;
  height: 10px;
  background: #ebeef0;
  float: left;
  border-radius: 50px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}
#smokeneedpanel md-switch {
  background-color: #7700b3;
  border-radius: 15px;
  border: 2px solid #a038d5;
  width: 62px;
  height: 32px;
  margin: 25px 3px;
}
#smokeneedpanel md-switch .md-thumb {
  left: 5px;
}
#smokeneedpanel md-switch .md-thumb {
  background-color: rgb(255, 196, 18);
}
#smokeneedpanel md-switch.md-checked .md-thumb {
  background-color: rgb(255, 196, 18);
}
#smokeneedpanel md-switch .md-container {
  width: 100%;
}
#smokeneedpanel md-switch .md-thumb-container {
  width: 59%;
}
#smokeneedpanel md-switch:not(.md-dragging) .md-thumb {
  transition: all 0.38s linear;
}
#smokeneedpanel md-switch .md-bar {
  display: none;
}
#smokeneedpanel td {
  padding: 0 3px;
  font-size: 1.17em;
  color: #d6b8ee;
}
#smokeneedpanel td.active {
  color: #ffc412;
}
/*md-dialog {
	max-height: none;
	overflow: initial;
	opacity: 1;
}*/
md-backdrop.md-dialog-backdrop {
  z-index: 80000;
}
.md-dialog-container,
md-tooltip,
md-toast,
.md-select-menu-container {
  z-index: 80001;
}
.pac-container {
  z-index: 80200;
}
.sweet-overlay {
  z-index: 100000;
}
.sweet-alert {
  z-index: 100001;
}
.slots-bunch {
  display: inline-block;
  width: 14.25%;
  float: left;
}
.single-slot {
  border: 1px solid #ccc;
  padding: 4px 2px;
  margin: 8px 3%;
  background: rgba(204, 204, 204, 0.55);
  font-size: 0.8em;
  border-radius: 5px;
  cursor: pointer;
  color: #333;
  text-align: center;
  white-space: nowrap;
}
.single-slot.inactive {
  background: transparent;
  opacity: 0.8;
  cursor: not-allowed;
}
.slots-bunch.active .single-slot.selected {
  border: 1px solid #aa00ff;
  box-shadow: 0px 0px 2px 0px #aa00ff;
}
.slots-bunch-title .week-stamp {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6em;
}
.slots-bunch-title .date-stamp {
  text-transform: none;
  text-align: center;
  font-size: 0.9em;
}
.slots-bunch-title {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
}
.slots-bunch.active .slots-bunch-title:after {
  content: "";
  border-bottom: 4px solid #aa00ff;
  width: 100%;
  position: absolute;
  width: 100%;
  bottom: -2px;
}
div#timeslots {
  float: left;
  width: 100%;
  margin-top: 35px;
  margin-left: 1px;
}
.emptycart {
  text-align: center;
  padding: 16px;
  float: left;
  width: 100%;
  font-size: 1.5em;
}
.emptycart a {
  margin-top: 20px;
}
.checkoutstepthree_showdate md-icon.md-datepicker-calendar-icon.ng-scope {
  background: url(../images/checkoutstep3_calender.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  right: 8px;
}
.checkoutstepthree_showdate md-icon.md-datepicker-calendar-icon.ng-scope svg {
  display: none;
}
.checkoutstepthree_showdate .md-datepicker-input-container {
  visibility: hidden;
  width: 1px;
}
md-switch.md-default-theme.md-checked .md-bar,
md-switch.md-checked .md-bar {
  background-color: #ffc412;
}
md-switch .md-bar {
  border-radius: 30px;
  height: 21px;
  left: -2px;
  position: absolute;
  top: 0px;
  width: 45px;
  margin: 0px 0px;
}
md-switch.md-default-theme.md-checked .md-thumb,
md-switch.md-checked .md-thumb {
  background-color: #ffffff;
  border: 2px solid #564111;
  height: 17px;
  width: 17px;
}
md-switch.md-default-theme.md-checked .md-ink-ripple,
md-switch.md-checked .md-ink-ripple {
  color: #ffc412;
}
md-switch .md-container {
  -moz-user-select: none;
  cursor: grab;
  float: left;
  height: 21px;
  margin-right: 0px;
  position: relative;
  width: 45px;
}
md-switch .md-thumb {
  background-color: #ffffff;
  border: 2px solid #564111;
  height: 17px;
  width: 17px;
}
md-switch .md-thumb-container {
  width: 23px;
}
#smokeneedpanel md-switch .md-thumb {
  border: 0px solid #564111;
  height: 20px;
  width: 20px;
}
#smokeneedpanel md-switch .md-container {
  -moz-user-select: none;
  cursor: grab;
  float: left;
  height: 24px;
  margin-right: 8px;
  position: relative;
  width: 100%;
}
md-switch {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mCSB_container_wrapper > .mCSB_container {
  padding-right: 0px !important;
  width: 100% !important;
}
.mCSB_container_wrapper {
  margin-right: 0px !important;
}
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-color: transparent !important;
}
.order-history {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;
  margin-bottom: 10px;
}
.order-history .-status {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 8px 15px;
  min-height: 60px;
}
.order-history .-rating {
  padding: 8px 0;
}
.order-history .order .-key {
  font-size: 1.3em;
  font-weight: 500;
}
.order-history .order .-timeslot {
  font-size: 1.1em;
  color: #a99b9b;
}
.order-history .order .-items {
  font-size: 1.5em;
}
.order-history .order .-rating .-rating-value .glyphicon-heart-empty:before {
  color: #7b7474;
}
.order-history .order .-rating .-rating-value .glyphicon-heart:before {
  color: #ffc412;
}
.innerpagemargintop .nav.navbar-nav .dropdown-menu {
  position: absolute;
}
.innerpagemargintop .nav.navbar-nav > li {
  position: relative;
}
.innerpagemargintop .nav.navbar-nav .dropdown-menu {
  animation-duration: 0s;
  animation-fill-mode: inherit;
}
.md-icon-button.md-button.md-ink-ripple {
  color: #564111 !important;
  font-size: 30px;
  opacity: 0.8;
}
/*md-dialog {
	margin: 0 auto;
	width: 960px;
}*/
md-toolbar {
  background: transparent none repeat scroll 0 0 !important;
  min-height: 64px;
}
.col-sm-4.enter-locationdiv > div {
  padding-top: 5px;
}
.col-sm-4.enter-locationdiv div {
  float: left;
  /*margin-top: 5px;*/
}
.menuulpadding {
  float: left;
  padding-left: 0 !important;
  width: 100%;
  margin: 0 !important;
}
.pushy.pushy-left {
  display: none;
}
#container {
  display: none;
}
.menuulpadding > li:last-child a:hover {
  background-color: transparent;
}
.navbar-shrink .signuplogin .search_icon,
.navbar-shrink .signuplogin .search_icon,
.navbar-shrink .signuplogin .search_icon {
  top: 0; /*transition: all 0.5s ease 0s;*/
}
.dropdown.cart-icon-set:hover {
  background-color: #e1b11f !important;
}

ul.menuulpadding li:hover {
  background-color: #e1b11f !important;
}

ul.menuulpadding li ul li:hover {
  background-color: transparent !important;
}

.signuplogin ul li:nth-child(4) a:hover,
.signuplogin ul li:nth-child(4) a:focus,
.signuplogin ul li:nth-child(4) a:active,
.signuplogin ul li:nth-child(4) a {
  background-color: transparent;
}
/*.layout-row {
	box-shadow: none;
	width: 98.5%;
	margin: auto;
}*/
.btm10.ng-scope {
  width: 100%;
  display: inline-block;
  position: relative;
}
.col-md-10.top-logo-sideacnt-cover.text-center {
  padding: 0;
}
/*md-dialog .md-actions, md-dialog md-dialog-actions {
	width: 100% !important;
	padding-left: 0;
	padding-right: 0
}*/
.layout-row input {
  color: #2c2011;
}
::-webkit-input-placeholder {
  color: #888888 !important;
}
.globalsearch md-autocomplete-wrap ::-webkit-input-placeholder {
  color: #d9a600 !important;
}
.globalsearch md-autocomplete-wrap input::-moz-placeholder {
  color: #d9a600;
  opacity: 1;
}
.globalsearch md-autocomplete-wrap input:-moz-placeholder {
  color: #d9a600;
  opacity: 1;
}
.globalsearch md-autocomplete-wrap input:-ms-input-placeholder {
  /* IE 10+ */
  color: #d9a600 !important;
  opacity: 1;
}
input::-moz-placeholder {
  color: #888888;
  opacity: 1;
}
input:-moz-placeholder {
  color: #888888;
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #888888 !important;
}
.reviewtable-cover {
  width: 100%;
  float: left;
}
.fullwidth.ng-scope.header {
  margin-bottom: 0;
}
.footer-container-borderset {
  border-top: 1px solid #3a3b40;
  margin-top: 59px;
  margin: 59px auto 0;
  width: 1200px;
  float: none;
}
.fullwidth.modifyscroll .md-container {
  left: 22px;
  top: 28px;
}
.md-dialog-content.alcohaladdadrebody.alcohal_reapeatorederbody {
  padding-top: 10px;
}
/*.alcohal_hisryorderboxcvr1:nth-child(3n+3) {
	margin-right: 0;
}*/
.btn.product_addcart.addcart-detail.top-set-cart {
  padding-top: 7px;
}
.ttile-divcover.ng-scope {
  display: inline-block;
}
.navbar.navbar-default.navbar-fixed-top {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -web-transition: all 0.3s ease 0s;
  padding: 0;
}
.navbar.navbar-default.navbar-fixed-top.navbar-shrink {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -web-transition: all 0.3s ease 0s;
  padding: 0;
}
.navbar-fixed-top.navbar-shrink .logos-inner-cover {
  max-width: 170px;
}
.main-header-botm-cover {
  vertical-align: middle;
  width: 100%;
}
.menuulpadding span {
  display: table-cell;
  vertical-align: middle;
}
.signuplogin a,
.signuplogin > a:hover,
.signuplogin a:focus {
  color: #2c2011;
  /* display: table-cell;*/
  float: none;
  /* height: 100%;*/
  left: 0;
  padding: 40px 15px;
  text-decoration: none;
  vertical-align: middle;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -web-transition: all 0.3s ease 0s;
}

.signuplogin li:hover,
.signuplogin li:focus {
  /*background-color: #e1b11f !important;*/
}
.navbar-fixed-top .signuplogin ul li {
  align-items: center;
  display: table;
  float: right;
  vertical-align: middle;
  cursor: pointer;
}
.navbar-fixed-top .main-header-botm-cover {
  height: 100px;
  left: 0;
  vertical-align: middle;
  width: 100%;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -web-transition: all 0.3s ease 0s;
}
.navbar-fixed-top .btm10.ng-scope {
  /*   height: 90px;*/
  position: relative;
  width: 100%;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  align-items: center;
}
.cart_icon {
  position: relative;
  top: 0;
}
.navbar-fixed-top.navbar-shrink .cart_icon {
  position: relative;
  top: 0;
}
.navbar-fixed-top.navbar-shrink .signuplogin ul li {
  display: table;
  float: right;
  height: 70px;
  vertical-align: middle;
  cursor: pointer;
}
.navbar-fixed-top.navbar-shrink .main-header-botm-cover {
  height: 70px;
  left: 0;
  vertical-align: middle;
  width: 100%;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -web-transition: all 0.5s ease 0s;
}
.navbar-fixed-top.navbar-shrink .btm10.ng-scope {
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  align-items: center;
  /* height: 70px;*/
  position: relative;
  width: 100%;
}
.navbar-fixed-top.navbar-shrink .cart_icon {
  background: rgba(0, 0, 0, 0) url("../images/cart.svg") no-repeat scroll center
    25px;
}
.navbar-fixed-top.navbar-shrink .search_icon {
  background: rgba(0, 0, 0, 0) url("../images/search.svg") no-repeat scroll
    center 25px;
}
.navbar-fixed-top.navbar-shrink .searchtop.searchtop100 .search_icon {
  background: rgba(0, 0, 0, 0) url("../images/search.svg") no-repeat scroll
    center 0;
}
.logoss {
  display: inline-block;
  float: left;
  left: 0;
  min-height: 100px;
  min-width: 100%;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  /*transition: all 0.3s ease 0s;*/
}
.logoss > a {
  display: table;
  text-align: center;
  width: 100%;
}
.img-responsive.logosmall {
  display: table-cell;
  max-height: inherit;
  transition: all 1s ease-in-out;
}
.img-responsive.logolarge {
  /*display: inherit;*/
  left: 0;
  position: relative;
  top: 0;
  vertical-align: middle;
}
.navbar-fixed-top.navbar-shrink .text-center .logosmall {
}
.navbar-fixed-top.navbar-shrink .img-responsive.logosmall {
  /*display: inherit;*/
  vertical-align: middle;
  transition: all 1s ease-in-out;
  width: 51px; /* width: 51px;*/
  opacity: 1;
}
.callshape {
  background: rgba(0, 0, 0, 0) url("../../images/Shapecall2.png") no-repeat
    scroll 0 center;
  height: 66px;
  padding: 9px;
  float: none;
  transition: all 0.5s ease 0s;
}
.callshape div {
  transition: all 0.5s ease 0s;
}
.col-md-2.homecallus_cover > a {
  /* display: table;
    float: left;
    height: 100%;
    width: 100%;*/
}
.navbar-fixed-top.navbar-shrink .logos-inner-cover {
  display: table;
  height: 70px;
  width: 100%;
}
.logos-inner-cover > a {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.navbar-fixed-top .logos-inner-cover {
  display: table;
  height: 100px;
  margin: 0 auto;
  min-width: 170px;
  position: relative;
  z-index: 9999;
}
.img-responsive.logosmall {
  /*display: inherit;*/
  left: 36%;
  position: absolute;
  top: 23px;
  transition: all 1s ease-in-out;
  vertical-align: middle;
  width: 51px;
  opacity: 0;
}
.navbar-fixed-top .searchtop.searchtop100 .img-responsive.logolarge {
  display: none;
}
.logoss.leftminusopacity.leftminus100 .img-responsive.logolarge,
.logoss.leftminusopacity.leftminus100 .img-responsive.logosmall {
  display: none;
}
/*.logo-top-img-main {
    position: relative;
}*/
.navbar-fixed-top.navbar-shrink .homechilled {
  color: #2c2011;
  float: left;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
}
.navbar-fixed-top.navbar-shrink .homecallus {
  font-size: 12px;
}
.navbar-fixed-top.navbar-shrink .callshape {
  background: rgba(0, 0, 0, 0) url("../../images/Shapecall3.png") no-repeat
    scroll 0 center;
  padding-top: 4px;
  -webkit-flex: 1; /* Safari 6.1+ */
  flex: 1;
  float: none;
  vertical-align: middle;
  transition: all 0.5s ease 0s;
}
.btm10.ng-scope {
  z-index: 9999;
}
.checkboxtotaldiv-text-font-size {
  color: #222222;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  text-align: right;
}

.checkoutstep5right-middle-first .checkboxtotaldiv-text-font-size {
  text-transform: uppercase;
}

.checkboxtotaldiv-text-botm-text {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  text-align: right;
}
.slots-bunch.active {
  color: #aa00ff;
}
.bootstrap-touchspin.vertical .addmore-count:focus,
.bootstrap-touchspin.vertical .addmore-count:active {
  color: #aa00ff !important;
}
.dropdown-menu.profil-dropdown > li {
  height: auto !important;
}
.modal-footer.alcohalfotter {
  border-radius: 0 0 7px 7px;
}
a.md-button.md-default-theme.md-focused:not([disabled]),
a.md-button.md-focused:not([disabled]),
.md-button.md-default-theme.md-focused:not([disabled]),
.md-button.md-focused:not([disabled]) {
  background-color: none !important;
}
.align-set-calendar.ng-binding {
  position: relative;
  top: -4px;
  left: -12px;
}
.productdetailbrudcumcover.cocktail-detailpagecover md-ink-bar {
  color: #aa00ff !important;
  background: #aa00ff !important;
}
.md-tab.ng-scope.ng-isolate-scope.md-ink-ripple.md-active span {
  color: #aa00ff !important;
}
md-checkbox.md-default-theme.md-checked .md-icon,
md-checkbox.md-checked .md-icon {
  background-color: rgba(170, 0, 255, 0.87) !important;
}
/*md-checkbox {
	width: 20px;
}*/
.combo_slider .dropdown-menu {
  min-width: 144px;
  top: 42px;
  width: 100%;
  border: medium none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.combo_slider .dropdown-menu > li > a {
  padding: 3px 11px;
}
.testimonial-item-outer {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}
.testimonial-left-inner {
  display: table;
  float: left;
  height: 100%;
  text-align: center;
  width: 100%;
}
.gift-card--inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.gift-card-mainouter .media-left {
  display: table;
  text-align: center;
  background: #f2f2f2;
  padding-right: 0;
}
.enter-gift-detail {
  color: #666;
  float: left;
  font-size: 15px;
  width: 100%;
  padding-bottom: 20px;
}
.media-body.party-detail-contentcover.gift-card-rightcover span {
  width: 100%;
  float: left;
  font-size: 14px;
  padding-bottom: 0px;
  color: #666;
}
.btn-success {
  background-color: #aa00ff !important;
  border-color: #d7d7d7;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff !important;
  border: 1px solid transparent !important;
}
.btn.default.btn-cls-gift.ng-binding.ng-scope {
  border: 1px solid #d7d7d7;
  margin-right: 5px;
  background: #fff;
  color: #222122;
  font-size: 15px;
}
.media-body.party-detail-contentcover.gift-card-rightcover > div {
  padding-bottom: 20px;
}
.gift-to-divcover > label {
  color: #666;
  font-size: 14px;
  font-weight: normal;
}
.gift-to-divcover {
  float: left;
  width: 100%;
}
.gift-card-innerform {
  float: left;
  width: 240px;
}
.gift-card-innerform input {
  height: 34px;
  min-height: inherit;
  margin-bottom: 8px;
  box-shadow: none;
  font-size: 14px;
  padding-bottom: 0;
  padding-top: 0;
}
.gift-card-innerform > label {
  float: left;
  width: 100%;
  font-weight: normal;
  font-size: 14px;
}
.gift-card-innerform span {
  float: right !important;
  width: auto !important;
}
.gift-to-divcover {
  padding-bottom: 12px !important;
}
.gift-check-contact {
  float: left;
  padding-top: 12px;
  width: 100%;
}
.mobile-sms-width.ng-scope {
  min-width: 207px;
  position: relative;
  top: 3px;
}
.gift-check-contact .ng-valid.ng-touched.md-checked.ng-dirty.ng-valid-parse {
  margin-bottom: 10px;
}
.addmore-count.ng-binding {
  float: left !important;
}
.checkoutmiddlevlauediv.party-checkout-detail > label {
  font-weight: normal;
  color: #666666;
  font-size: 14px;
}
.btm-text-giftnot {
  width: 100%;
  color: #949494;
  font-size: 14px;
}
.btm-text-giftnot {
  float: left;
  width: 100%;
}
md-checkbox.md-default-theme .md-icon,
md-checkbox .md-icon {
  border-color: rgba(215, 215, 215, 1);
}
md-checkbox.md-default-theme.md-checked .md-icon::after,
md-checkbox.md-checked .md-icon::after {
  border-color: rgba(255, 255, 255, 1);
}
.max-char-color {
  color: #999 !important;
  font-size: 12px !important;
}
.gift-product-outer {
  display: table;
}
.prod-pic-midle-set {
  display: table-cell;
  vertical-align: middle;
}
.gift-lstin-mainouter .prod_desc {
  color: #37474f;
  height: 33px;
  text-align: center;
}
.gift-lstin-mainouter .prodcombo_addtocartbutton {
  text-align: center;
}
.gift-lstin-mainouter .prodcombo_addtocartbutton img {
  display: inline-block;
}
.top-tabmainouter .owl-prev {
  left: -10px;
  position: absolute;
  top: 13px;
  background: none !important;
  border: 0 none;
}
.top-tabmainouter .owl-next {
  position: absolute;
  right: -9px;
  top: 13px;
  background: none !important;
  border: 0 none;
}

/*.tab-content .owl-prev {
	left: 0px;
	position: absolute;
	top: 46%;
	background: none !important;
	border: 0 none;
}
.tab-content .owl-next {
	position: absolute;
	right: 0px;
	top: 46%;
	background: none !important;
	border: 0 none;
}*/

.tabbable .owl-carousel {
  width: 100%;
}
.tab-content.fullwidth .owl-item {
  /* width: 180px !important;*/
}
.text-capitalize {
  color: #5f5f5f;
  font-size: 13px;
  text-transform: capitalize;
}
.combo_slider .item {
  margin: 9px 14px;
  float: none;
}
.top-tabmainouter .owl-item {
  float: left;
  padding: 11px 7px;
  white-space: nowrap;
}
.fullwidth.top-tabmainouter > .right-crousel {
  float: right;
  margin-right: 16px;
  width: 77%;
  margin-bottom: 0 !important;
  min-height: 10px;
}
.top-tabmainouter.item {
  margin: 0 16px;
}
.top-tabmainouter .owl-wrapper-outer {
  top: -5px;
}
.top-tabmainouter .item.ng-scope.active a,
.top-tabmainouter .item:hover a,
.top-tabmainouter .item:focus a,
.top-tabmainouter .item:active a {
  background-color: transparent;
  border-radius: none;
  border: 0px solid transparent;
  border-bottom: 2px solid #aa00ff !important;
  color: #aa00ff;
  cursor: default;
}
.form-group.sortbydropdown-outer {
  border: none;
  float: right;
  margin-bottom: 0;
  padding-top: 4px;
  width: 20%;
}
.dropdown.productdropdownbtn.open span {
  transform: rotate(-180deg);
}
.combo_slider .nav .sortby-divmain li a:focus,
.combo_slider .nav .sortby-divmain li a:hover,
.combo_slider .nav .sortby-divmain lia:active {
  color: #5f5f5f !important;
  border-bottom: none !important;
}
.col-xs-12.col-sm-3.col-md-3.comboproductitems:hover {
  -webkit-box-shadow: 0 0 10px 5px #d6dce0;
  -moz-box-shadow: 0 0 10px 5px #d6dce0;
  box-shadow: 0 0 10px 5px #d6dce0;
  transition: all 0.5s ease 0s;
}

.proHoverEffect:hover {
  -webkit-box-shadow: 0 0 10px 5px #d6dce0;
  -moz-box-shadow: 0 0 10px 5px #d6dce0;
  box-shadow: 0 0 10px 5px #d6dce0;
  transition: all 0.5s ease 0s;
}

.product-listcover2 {
  float: left;
  padding: 0 15px;
  width: 100%;
}
/*.add-bulk-wishlist-icon {
    display: none;
    position: relative;
}*/
.proHoverEffect:hover .add-wishlist-icondiv,
.comboproductitems:hover .add-wishlist-icondiv {
  display: block;
}
.proHoverEffect:hover .bulk-dis-icon,
.comboproductitems:hover .bulk-dis-icon {
  display: block;
}
.add-wishlist-icondiv {
  background: rgba(178, 25, 255, 0.95) none repeat scroll 0 0;
  border-radius: 4px;
  display: none;
  height: 30px;
  margin-bottom: 0;
  padding-top: 0px;
  position: relative;
  text-align: center;
  top: 0;
  width: 30px;
  z-index: 2;
}
.add-wishlist-icondiv img {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.bulk-dis-icon {
  background: rgba(255, 202, 41, 0.95) none repeat scroll 0 0;
  border-radius: 4px;
  display: none;
  height: 30px;
  position: relative;
  top: 0;
  width: 30px;
  z-index: 2;
  text-align: center;
  padding-top: 4px;
}
.wishlist-main-outer {
  position: relative;
}
.add-icon-main:hover .wishlist_discount {
  /*display:block;*/
  border-radius: 4px;
  left: -4px;
  margin-left: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
}
.wishlist_discount_bulk {
  border-radius: 5px;
  height: 100%;
  left: -1px;
  margin-left: -100%;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 1;
}
.bulk-icon-maincover:hover .wishlist_discount_bulk {
  margin-left: 0;
  transition: all 0.5s ease 0s;
}
.add-bulk-wishlist-icon {
  position: absolute;
  top: 42px;
  width: 100%;
}
.add-icon-main {
  border-radius: 4px;
  float: left;
  overflow: hidden;
}
.add-icon-main:hover {
  width: 100%;
}
.bulk-icon-maincover {
  border-radius: 4px;
  float: left;
  overflow: hidden;
}
.bulk-icon-maincover:hover {
  width: 100%;
}
.add-bulk-iner-widthset {
  border-radius: 4px;
  float: left;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.added {
  float: left;
  width: 100%;
}

.checkoutmiddlevlauediv .spin-border,
.spin-border {
  box-shadow: 0 0 5px 2px #e5c8f6;
  border-radius: 4px;
  border: 1px solid #aa00ff;
}

.addmore .spin-border {
  box-shadow: 0 0 0px 0px #e5c8f6;
  border-radius: 0px;
  border: 0px solid #aa00ff;
}

.added .addmore-count.ng-binding {
  /*border: 1px solid #aa00ff;*/
}
.added .btn.btn-default.bootstrap-touchspin-up {
  /*background: #aa00ff;
	border-top: 1px solid #aa00ff;
	border-right: 1px solid #aa00ff;*/
}
.added .glyphicon.glyphicon-plus {
  color: #333;
}

.added .glyphicon.glyphicon-plus {
  color: #333;
}

.added .btn.btn-default.bootstrap-touchspin-down {
  /*border-bottom: 1px solid #aa00ff;
	border-right: 1px solid #aa00ff;*/
}
.added
  .input-group.bootstrap-touchspin.ng-isolate-scope.ng-dirty.ng-valid.ng-valid-parse.vertical {
  bottom: 0px;
}
.added .btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: none;
}
.added .input-group-btn-vertical.ng-scope {
  border-radius: 4px;
}
.single_slider .owl-theme .owl-controls {
  padding-bottom: 20px;
}
.label.label-warning.ng-binding.ng-scope {
  float: left;
}
.prod_addtocart .addmore-count.ng-binding {
  float: none !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /*md-dialog {
	  margin: inherit;
  }*/
}
md-radio-button.md-default-theme.md-checked .md-off,
md-radio-button.md-checked .md-off {
  border-color: rgba(170, 0, 255, 0.87) !important;
}
md-radio-button.md-default-theme .md-on,
md-radio-button .md-on {
  background-color: rgba(170, 0, 255, 0.87) !important;
}
.wishlistpopuppinkbox {
  background: #aa00ff;
  border-radius: 5px;
  float: left;
  padding: 17px;
  width: 100%;
}

.wishlistpopuppinkbox .additempopuppinkboxright {
  padding-left: 0px;
  text-align: center;
}

.notifyme {
  background: url("../images/add-wish-icon.png") no-repeat center center;
  width: 50px;
  height: 50px;
}
.footer1_sociallink a {
  margin-right: 16px;
}

.enter-locationdiv md-autocomplete {
  margin-top: 5px;
}
.enter-locationdiv md-autocomplete-wrap {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.enter-locationdiv md-autocomplete-wrap button {
  display: none;
}

.alcohalosavebntcvr button:disabled {
  background: #bbb;
}
.selectAddress {
  position: relative;
  width: 100%;
  float: left;
  margin: 5px 0 0 0;
}
.cartaddresses .addressbokk-trvaichacovr,
.cartaddresses .addressbokk-addnewadresscovr {
  width: 48.5%;
}

.cartaddresses .addressbokk-addnewadresscovr {
  padding: 25px 26px;
}

.addressbokk-trvaichacovr:hover,
.adressmiddlepartcvr2:hover,
.addressbokk-trvaichacovr.active {
  border: 1px solid #8d00d4;
  /*box-shadow: 0 0 2px #8d00d4;*/
}
.selectAddress .yelo_btn {
  color: #444;
  background-color: #ddd;
}
.addressbokk-trvaichacovr.active .selectAddress .yelo_btn {
  color: #564111;
  background-color: #ffc412;
}
.paymenttype img.hidethumb {
  display: none;
  position: absolute;
  /*top: 47%;
  left: 40%;
  */
  top: 35%;
  left: 34%;
}
.paymenttype.active img.hidethumb {
  display: block;
}

.paymenttype img {
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.paymenttype.active > div > div > img {
  margin-bottom: 15px;
}

.brd-bot {
  border-bottom: 1px dashed #ffffff;
  margin: 0 0 5px;
  padding: 0 0 5px;
}
.mobilemenu {
  font-weight: bold;
  letter-spacing: 1px;
}
.tag-pic {
  position: relative;
  right: 0;
  top: 0;
}

.via-padd {
  padding: 52px 0 45px;
}

.productaddedpopup:hover + a + span + div.currentorderpopup {
  display: none;
}

/*.currentstep span{
	color: #000000 !important;
}*/

.alcohlchekboxdiv12 md-checkbox .md-icon {
  border-width: 1px;
  border-color: rgba(215, 215, 215, 1) !important;
}

.bootstrap-touchspin-up:hover .glyphicon,
.bootstrap-touchspin-down:hover .glyphicon {
  color: #ffc412;
}

.additempopuppinkboxrightinner a {
  background-color: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
  color: inherit !important;
  border: none !important;
  text-decoration: none !important;
  background-image: none !important;
}

.emptycartbox {
  left: calc(26% - 145px);
}

.no-pro-found-table {
  width: 100%;
  text-align: center;
  display: table;
}

.no-pro-found-cell {
  height: 200px;
  display: table-cell;
  vertical-align: middle;
}

.steps-cover {
  width: 100%;
  float: left;
  position: relative;
}

.step-ratio {
  width: 25%;
  float: left;
}

.step-individual {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ebeef0;
  border-radius: 50%;
  position: relative;
  z-index: 3;
  cursor: pointer;
}

/*.step-individual.active{
	background-color: #ffc412;
}*/

.step-progressbar {
  height: 10px;
  left: 10%;
  position: absolute;
  top: 25px;
  width: calc(100% - 20%);
  background-color: #ebeef0;
  z-index: 3;
}

.progressbar-state {
  width: 100%;
  float: left;
  position: relative;
}

.progressbar-loading {
  width: 0%;
  position: absolute;
  background-color: #ffc412;
  height: 4px;
  left: 0px;
  top: 3px;
  transition: all 0.5s linear 0s;
}

.stepcount-1 .progressbar-loading {
  width: 0%;
}
.stepcount-2 .progressbar-loading {
  width: 33%;
}
.stepcount-3 .progressbar-loading {
  width: 66%;
}
.stepcount-4 .progressbar-loading {
  width: 100%;
}
.stepcount-5 .progressbar-loading {
  width: 100%;
}

.step-1 {
  background-image: url(../images/step-1.png);
}

.stepcount-1 .step-1 {
  background-color: #ffc412;
}

.stepcount-1 .step-1,
.stepcount-2 .step-1,
.stepcount-3 .step-1,
.stepcount-4 .step-1,
.stepcount-5 .step-1 {
  border: 3px solid #ffc412;
  background-image: url(../images/step-1h.png);
  /*background-color: #ffc412;*/
  transition: all 0s linear 0.5s;
}

.step-2 {
  background-image: url(../images/step-2.png);
}

.stepcount-2 .step-2 {
  background-color: #ffc412;
}

.stepcount-2 .step-2,
.stepcount-3 .step-2,
.stepcount-4 .step-2,
.stepcount-5 .step-2 {
  background-image: url(../images/step-2h.png);
  /*background-color: #ffc412;*/
  border: 3px solid #ffc412;
  transition: all 0s linear 0.5s;
}

.step-3 {
  background-image: url(../images/step-3.png);
}

.stepcount-3 .step-3 {
  background-color: #ffc412;
}

.stepcount-3 .step-3,
.stepcount-4 .step-3,
.stepcount-5 .step-3 {
  background-image: url(../images/step-3h.png);
  /*background-color: #ffc412;*/
  border: 3px solid #ffc412;
  transition: all 0s linear 0.5s;
}

.step-4 {
  background-image: url(../images/step-4.png);
}

.stepcount-4 .step-4 {
  background-color: #ffc412;
}

.stepcount-4 .step-4,
.stepcount-5 .step-4 {
  background-image: url(../images/step-4h.png);
  /*background-color: #ffc412;*/
  border: 3px solid #ffc412;
  transition: all 0s linear 0.5s;
}

.step-5 {
  background-image: url(../images/step-5.png);
}

.stepcount-5 .step-5 {
  background-image: url(../images/step-5h.png);
  background-color: #ffc412;
  transition: all 0s linear 0.5s;
}

.stepcount-1 .step-1 + .steps-text {
  color: #564111;
}

.stepcount-2 .step-2 + .steps-text {
  color: #564111;
}

.stepcount-3 .step-3 + .steps-text {
  color: #564111;
}

.stepcount-4 .step-4 + .steps-text {
  color: #564111;
}

.stepcount-5 .step-5 + .steps-text {
  color: #564111;
}

.new-cover {
  padding: 0 0 0 0 !important;
}

.new-cover .enter-locationdiv {
  padding: 24px;
}

.no-pad {
  padding: 0 !important;
}

.addmore .bootstrap-touchspin-down {
  margin-right: -15px !important;
}

.addmore .bootstrap-touchspin-up {
  margin-left: -15px !important;
}

.mCSB_buttonUp,
.mCSB_buttonDown {
  padding: 0 !important;
}

.amt-title {
  padding-bottom: 5px !important;
}

a.core-block {
  position: relative;
}

.yellow-tag {
  bottom: 52px;
  left: 0;
  min-height: 20px;
  float: left;
  margin-right: 5px;
}

button {
  opacity: 0.9;
}

button:hover {
  opacity: 1;
}

.side-menu-responsive .dropdown-menu > li > a:focus,
.side-menu-responsive .dropdown-menu > li > a:hover {
  background-color: transparent;
}

.wishprev {
  opacity: 1;
  color: #555555;
}

.md-autocomplete-suggestions li {
  margin: 10px 0;
}

.checkoutstepthree_showdate .md-button.md-icon-button {
  width: 100%;
  height: 57px;
  padding: 0px;
  margin: 0px;
  min-height: 18px;
  line-height: 0px;
  top: 0px;
  left: 0px;
  border-radius: 0px;
  position: absolute;
}

.checkoutstepthree_showdate .md-icon-button + .md-datepicker-input-container {
  left: calc(50% - 173px);
  margin: 0;
  padding: 0;
  position: absolute;
  width: 50%;
}

.checkoutstepthree_showdate md-icon {
  width: 17px;
  height: 18px;
  min-width: 17px;
  min-height: 18px;
}

.checkoutstepthree_showdate .md-datepicker-input-container {
}

.checkoutstepthree_showdate md-datepicker {
  padding: 0px;
  margin: 0px;
}

.checkout-date-cover {
  width: 100%;
  float: left;
  text-align: center;
}

.md-datepicker-input-mask {
  height: 0px !important;
}

.purple-button,
.purple-button:focus {
  background: #aa00ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  float: left;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 5px 20px;
  text-transform: uppercase;
}

.purple-button:hover {
  color: #ffffff;
}

.alcohaladdadrebody .no-pad {
  padding: 0 1px 0 0 !important;
}

.cock-img {
  height: 82px;
  width: 100%;
  float: left;
  text-align: center;
}

.cock-img img {
  display: inline-block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.repaet-layout-height {
  display: inline-block !important;
}

.md-toast-content {
  position: relative;
}

.arrow-popup {
  background: #ffffff none repeat scroll 0 0;
  height: 20px;
  left: 50%;
  position: absolute;
  top: -5px;
  transform: rotate(44deg);
  width: 20px;
  z-index: -3;
}

.product-detail-right-set {
  position: relative;
}

.skew2 {
  background: #ffffff none repeat scroll 0 0;
  bottom: -1px;
  position: absolute;
  transform: skewX(45deg);
  z-index: 3;

  background: #fff none repeat scroll 0 0;
  height: 67%;
  left: -35%;
  position: absolute;
  width: 87px;
}
.skew {
  background: #ffffff none repeat scroll 0 0;
  position: absolute;
  top: 0;
  transform: skewX(-45deg);
  z-index: 3;
  background: #fff none repeat scroll 0 0;
  height: 67%;
  left: -35%;
  position: absolute;
  width: 87px;
}

.tag-purple-new {
  background: #aa00ff none repeat scroll 0 0;
  border-radius: 0 2px 2px 0;
  float: right;
  position: relative;
  max-width: 195px;
  width: auto;
  margin-bottom: 7px;
}

.tag-purple-new-pos {
  position: absolute;
  top: 10px;
  right: -5px;
  width: 195px;
  word-wrap: break-word;
  overflow: hidden;
}

.tag-yellow-new {
  background: #ffc412 none repeat scroll 0 0;
  border-radius: 0 2px 2px 0;
  float: right;
  position: relative;
  max-width: 195px;
  width: auto;
  margin-bottom: 7px;
}

.tag-lightyellow-new {
  background: #f0ad4e none repeat scroll 0 0;
  border-radius: 0 2px 2px 0;
  float: right;
  position: relative;
  max-width: 195px;
  width: auto;
  margin-bottom: 7px;
}

.has-sale-div .prddetailtitle,
.has-sale-div .prddetailsubexttitle,
.has-sale-div .prddetailnormaltext,
.has-sale-div .prddetailnormaltext {
  width: 57%;
  position: relative;
  z-index: 4px;
  word-break: break-word;
}

.transparent-bg {
  background-color: transparent !important;
}
.bordernone {
  border: none !important;
}
.borde-subtotal {
  border-right: 1px solid #ddd !important;
}
.chekoutstep5-table {
  width: 100%;
  float: left;
}
.chekoutstep5-table .checkoutstep5-left-first {
  padding: 0px;
}
.chekoutstep5-table table td:not([class="childtd"]) {
  padding: 14px 10px 13px !important;
}
.chekoutstep5-table .checkoutstep5-left-first-left {
  width: 100%;
}
.chekoutstep5-table .checkoutstep5right-middle-title {
  float: right;
  width: auto;
}
.chekoutstep5-table .checkoutstep5right-bottom-title {
  float: right;
  width: auto;
}
.chekoutstep5-table .prod_chilled,
.checkoutmiddletable .prod_chilled {
  margin-bottom: 0px;
}
.chekoutstep5-table .new-img {
  display: inline-block;
  max-height: 50px !important;
  vertical-align: top;
}
.listimgborder {
  float: left;
  padding: 0 5px;
  width: 70px;
}
.listimgborder span {
  border: 1px solid #dddddd;
  float: left;
  height: 57px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  position: relative;
}
.listimgborder span img {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}
#toastBounds {
  /*margin-top:10px;*/
  width: 158px;
}
td.childtd {
  padding: 0px !important;
  border-bottom: 1px solid #ddd !important;
  border-top: 0 none !important;
}
td.childtd:last-child {
  border: 0 none !important;
}
.wraptxt {
  white-space: pre-line;
}
.menu-phone-icon {
  float: left;
}
.menu-phon-right {
  padding-left: 80px;
}
.menu-phon-call {
  font-size: 17px;
  font-weight: 400;
}
.menu-phon-number {
  font-size: 22px;
  font-weight: 500;
}
.combo_sliderProduct md-tabs.md-default-theme md-ink-bar,
md-tabs md-ink-bar {
  background: #aa00ff !important;
}
.menu-phone-div {
  float: left;
  width: 100%;
  padding: 16px 16px 33px;
}
#fboverlay {
  color: #fff;
}
.procatname {
  white-space: normal;
  line-height: normal;
}
md-toast.notify.bottom {
  float: right !important;
}
.date-selectbox {
  background: #ffc412 url(../images/producticon2.png) no-repeat right center;
  border: none;
  box-shadow: none;
  position: relative;
  width: 100px;
  cursor: pointer !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: "";
  appearance: none;
  height: 50px;
}
.date-selectbox:focus {
  box-shadow: none;
}

.cmspage-cover {
  width: 100%;
  float: left;
  background-color: #ffffff;
  border: solid 1px #dee2e3;
  border-radius: 4px;
  padding: 0 15px 44px 28px;
}
.cms-rightpanel {
  text-align: center;
}
.cms-rightpanel img {
  display: inline-block;
}
.cms-rightpanelMobile {
  display: none;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
.cms-rightpanelMobile img {
  display: inline-block;
}
.cmspage-cover h3.ng-binding {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
}
.cmspage-cover p {
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.cmspage-formcrv {
  width: 100%;
  float: left;
  margin-top: 27px;
}
.cmspage-formcrv label.control-label {
  margin-bottom: 13px;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
}
.cmspage-formcrv .form-group {
  margin-bottom: 20px;
}
.cmspage-formcrv .form-control {
  height: 44px;
}
.cmspage-formcrv .purple-button {
  display: inline-block;
  height: 40px;
  width: 130px;
  margin-top: 20px;
}
.cartstepone-cover .owl-carousel .owl-wrapper-outer {
  padding-left: 0px;
  padding-right: 0px;
}
.cartstepone-cover .owl-carousel .owl-item {
  float: left;
  padding: 0 10px;
}

.cartstepone-cover .owl-carousel .owl-item {
  width: 179px !important;
}
.registeremailcvr md-checkbox {
  margin-bottom: 0px;
}
md-content.globalsearch md-autocomplete {
  width: 95% !important;
}
md-checkbox.md-checked.md-focused .md-container::before {
  background: none !important;
}

.productbeer-headtitleouter {
  float: left;
  width: 100%;
}

.productbeer-headtitleouter .productbeerfiltertoptitle {
  width: 50%;
}

.productbeer-headtitleouter .productbeerfiltertoptitle.productbeer-point-rw {
  float: right;
  width: auto;
}

.announcement-enable-wrapper {
  padding-top: 60px;
}
.announcement-enable-wrapper .announcement-bar {
  width: 100%;
  color: #ffffff;
  float: left;
  background-color: rgb(156, 39, 176);
  /*position: fixed;*/
  /*padding: 5px 0;*/
  z-index: 99;
  top: 0px;
  text-align: center;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -web-transition: all 0.3s ease 0s;
  position: relative;
}
.announcement-enable-wrapper .announcement-enable {
  /*top: 60px;*/
}

.announcement-enable-wrapper .announcement-bar a {
  display: inline-block;
}

.announcement-container img {
  max-height: 40px;
}

.correct-time {
  background-color: #f0ad4e !important;
  padding: 2px 4px;
  border-radius: 2px;
  color: #fff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 600;
  margin-right: 3px;
  white-space: nowrap;
}

.tgcontain {
  display: inline;
}
.cock-party-content .cocktail-leftimgdiv {
  padding-right: 0;
  position: relative;
}

.add-wishlist-icondiv svg {
  position: absolute;
  vertical-align: middle;
  top: 0;
  text-align: center;
  margin: auto;
  display: inline-block;
  bottom: 0;
  right: 0;
  left: 0;
}

.bulk-dis-icon svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.outer {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.outer .announcement-container {
  display: inline-block;
  padding: 6px 0px;
}

.crossbtn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 37px;
}

.netsinfo {
  float: left;
  width: 100%;
  padding: 30px 20px;
  text-align: center;
  color: #666;
}

@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.saving span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}

.saving span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}

.paynow md-dialog {
  min-height: 100%;
  min-width: 100%;
}

.paynow md-toolbar {
  background-color: rgb(156, 39, 176) !important;
  color: rgba(255, 255, 255, 0.87);
  min-height: auto;
}

.paynow md-toolbar h2 {
  width: 100%;
  text-align: center;
}

.payNowSteps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}
.payNowStepsMain{
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .payNowSteps {
    display: block;
  }
  .payNowStepsMain{
    display: none;
  }

  .newpayment-container{
    padding: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .payNowSteps {
    display: block;
  }
  .payNowStepsMain{
    display: none;
  }
}

/*ADYEN LIKE UI*/
.newpayment-container{
  float: left;
  width: 100%;
}

.newpaymentui{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.newpaymentui-payment-method:first-child {
    margin-top: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.newpaymentui-payment-method:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.newpaymentui-payment-method.selected {
    transition: margin .15s cubic-bezier(.4,0,.2,1) 0ms,opacity .3s ease-out;
    background: #f7f8f9;
    border: 1px solid #e6e9eb;
    margin: 8px 0;
    border-radius: 12px;
    cursor: default;
}

.newpaymentui-payment-method {
    position: relative;
    background: #fff;
    border: 1px solid #e6e9eb;
    cursor: pointer;
    margin-top: -1px;
    width: 100%;
    transition: opacity .3s ease-out;
    outline: none;
}

.newpaymentui-payment-method__header {
    align-items: center;
    color: #00112c;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1em;
    padding: 16px 16px 16px 46px;
    position: relative;
    transition: background .1s ease-out;
    width: 100%;
}
.newpaymentui-payment-method__header__title {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 16px;
    max-width: 100%;
}

.newpaymentui-payment-method__image__wrapper {
    width: 45px;
    position: relative;
    margin-right: 8px;
    display: inline-block;
}

.newpaymentui-payment-method__image__wrapper img{
  width: 100%;
}

.newpaymentui-payment-method__details {
    padding: 0 16px;
    position: relative;
    display: none;
}

.selected .newpaymentui-payment-method__details{
    display: block;
}

.selected .cardnwallet{
    display: none;
}

.dropintool{
    padding: 0;
    margin: 0;
    background: transparent;
}

.licardwallet.selected{
    background-color: #FFF !important;
    border: 0 !important; 
    transition: all 0.3s ease-out;
}


.newpaymentui-payment-method__details__content{
    margin: 0 0 16px;
}

.newpaymentui__button {
    background: #AB4EFE;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    height: 48px;
    margin: 0;
    padding: 15px;
    text-decoration: none;
    transition: background .3s ease-out,box-shadow .3s ease-out;
    width: 100%;
}

.adyen-checkout__button{
  background: #AB4EFE !important;
}

.adyen-checkout__payment-method__radio--selected{
  background: #AB4EFE !important;
}

.adyen-checkout__checkbox__input:checked+.adyen-checkout__checkbox__label:after{
    border: 1px solid #AB4EFE;
    background-color: #AB4EFE;
}

.newpaymentui-payment-method__radio {
    position: absolute;
    background-color: #fff;
    border: 1px solid #b9c4c9;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    left: 16px;
    transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}

.newpaymentui-payment-method__radio:after {
    content: "";
    display: block;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    height: 6px;
    width: 6px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.newpaymentui-payment-method:hover:not(.newpaymentui-payment-method.selected) .newpaymentui-payment-method__radio {
    border-color: #99a3ad;
    box-shadow: 0 0 0 2px #d4d9db;
    cursor: pointer;
}
.newpaymentui-payment-method.selected .newpaymentui-payment-method__radio {
    background-color: #AB4EFE;
    border: 0;
    transition: all 0.3s ease-out;
}

.newpaymentui-payment-method.selected .newpaymentui-payment-method__radio:after {
    -webkit-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.newpaymentui-payment-method__brands {
    display: flex;
    flex-wrap: wrap;
    margin: 4px 0;
    height: 19px;
    flex-basis: auto;
    flex-shrink: 1;
    text-align: right;
    overflow: hidden;
}

.newpaymentui-payment-method__brands img {
    width: 28px;
    height: 19px;
}

.newpaymentui-payment-method__brands .newpaymentui-payment-method__image__wrapper {
    display: inline-block;
    margin-right: 4px;
    height: 19px;
    width: 28px;
    transition: opacity .2s ease-out;
}

.adyen-checkout__payment-method__radio--selected{
    background-color: #AB4EFE !important;
}

/*ADYEN LIKE UI*/

.cartterms{
  margin-top: 10px;margin-left: 10px;
}
.cartterms a {
  color: #385898;
  text-decoration: underline;
}

#dropin-container{
  margin-top: 10px;
}


/*diageo-18-Nov-21*/
.top-diageo-wrapper {
  width: 100%;
  float: left;
  display: table;
  background-color: #FFFFFF;
  padding: 15px;
  border-radius: 5px;
}
.top-diageo-title {
  width: calc(100% - 160px);
  display: table-cell;
  vertical-align: middle;
  color: #BE3A4D;
  font-size: 32px;
  text-transform: uppercase;
}
.top-diageo-img {
  width: 160px;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.wgs_top-diageo-img {
  width: 299px;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.top-diageo-img img {
  width: 100%;
}
.diageo-flavour-wrapper {
  width: 100%;
  float: left;
  margin-top: 15px;
  padding: 10px;
  background-color: #B9975A;
  text-align: center;
  border-radius: 5px;
}
.wgs-flavour-wrapper {
  width: 100%;
  float: left;
  margin-top: 15px;
  padding: 10px;
  background-color: #1e1a33;
  text-align: center;
  border-radius: 5px;
}
.diageo-flavour-tile {
  width: 32%;
  display: inline-block;
  padding: 0 15px;
}
.diageo-flavour-img {
  width: 100%;
  float: left;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.diageo-flavour-text {
  width: 100%;
  float: left;
  text-align: center !important;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 0;
}
.wgs-flavour-text {
  width: 100%;
  float: left;
  text-align: center !important;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 0;
}
.diageo-flavour-tile:hover .diageo-flavour-text {
  color: #000000;
}
.diageo-flavour-tile:hover .wgs-flavour-text {
  color: #FFFFFF;
}
.diageo-slider-wrapper {
  width: 100%;
  float: left;
}
.diageo-slider-box {
  width: 100%;
  float: left;
}
.banner-slider-tile {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 15px;
}
.banner-slider-img {
  width: 100%;
  float: left;
}
.banner-slider-img img {
  width: 100%;
  border-radius: 5px;
}
.banner-slider-box {
  position: absolute;
  top: 50%;
  color: #FFFFFF;
  transform: translateY(-50%);
}
.banner-slider-content {
  width: 100%;
  float: left;
  /* max-width: 450px; */
  max-width: 550px;
  padding: 0 20px;
}
.banner-slider-content h1 {
  font-size: 50px;
}
.banner-para {
  font-size: 17px;  
}
.diageo-background-wrapper {
  width: 100%;
  float: left;
}
.diageo-tile-background{
  width: 100%;
  float: left;
  background-color: #a40033;
  text-align: center;
  padding: 15px 0;
  color: #FFFFFF;
  border-radius: 5px;
  margin: 15px 0;
  font-size: 35px;
}
.wgs-tile-background{
  width: 100%;
  float: left;
  background-color: #1e1a33;
  text-align: center;
  padding: 15px 0;
  color: #FFFFFF;
  border-radius: 5px;
  margin: 15px 0;
  font-size: 35px;
}
.diageo-alcohol-wrappwer {
  width: 100%;
  float: left;
}
.diageo-scroll-for-more {
  margin-bottom: 15px;
  text-align: center;
}
.diageo-alcohol-tile {
  width: 100%;
  float: left;
  display: table;
  margin-top: 15px;
  background-color: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
}
.diageo-alcohol-tile-img {
  width: 434px;
  display: table-cell;
  vertical-align: middle;
}
.diageo-alcohol-tile:nth-child(odd) .diageo-alcohol-tile-img img {
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.diageo-alcohol-tile:nth-child(even) .diageo-alcohol-tile-img img {
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.diageo-alcohol-tile-content {
  width: calc(100% - 434px);
  display: table-cell;
  vertical-align: middle;
}
.diageo-alcohol-bottel-box {
  width: 50%;
  float: left;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}
.diageo-alcohol-bottel-img {
  width: 100%;
  float: left;
}
.diageo-alcohol-bottel-title {
  width: 100%;
  float: left;
  margin-top: 10px;
  color: #67676d;
  font-size: 19px;
  margin-bottom: 0;
}
.diageo-alcohol-bottel-value {
  width: 100%;
  float: left;
  text-align: center !important;
  margin-top: 10px;
  color: #000000;
  font-size: 22px;
  margin-bottom: 0;
}
.diageo-alcohol-bottel-btn {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.diageo-alcohol-bottel-btn .cartbtn {
  display: inline-block;
}
.stay-tuned-img {
  width: 100%;
  float: left;
}
.stay-tuned-img iframe {
  width: 100%;
  border-radius: 5px;
}
.diageo-drink-cover {
  width: 100%;
  float: left;
  background-color: #FFFFFF;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
}
.drink-text {
  width: 100%;
  display: inline-block;
  max-width: 500px;
  color: #656565;
  font-size: 19px;
  margin-bottom: 0;
  margin-top: 15px;
}
.diageo-drink-box {
  width: 100%;
  float: left;
  margin-top: 15px;
}
.diageo-drink-tile {
  width: 25%;
  float: left;
  padding: 7px;
}
.diageo-drink-tile img {
  width: 100%;
}
.diageo-alcohol-slider-box {
  width: 100%;
  float: left;
  background-color: #FFFFFF;
  padding: 15px;
  border-radius: 5px;
}
.diageo-alcohol-slider-box .diageo-alcohol-bottel-box {
  padding: 30px 0;
}
.classic-wrapper {
  width: 100%;
  float: left;
  position: relative;
}
.classic-left-box {
  width: 510px;
  float: left;
  /*background-color: #B9975A;
  padding: 15px 75px 30px 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;*/
}
#cocktailRecipes .owl-theme .owl-controls .owl-buttons div {
  color: #a40033 !important;
}
.classic-title {
  width: 100%;
  float: left;
  color: #FFFFFF;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 0;
}
.classic-title span {
  color: #000000;
}
.classic-para {
  width: 100%;
  float: left;
  color: #ffffff;
  text-align: left !important;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 15px;
  padding-left: 8px;
  border-left: 1px solid #FFFFFF;
}
.classic-border-para-box {
  width: 100%;
  float: left;
  margin-top: 15px;
}
.classic-border-para {
  width: 100%;
  float: left;
  border-top: 1px solid #FFFFFF;
}
.classic-border-para:last-child {
  border-bottom: 1px solid #FFFFFF;
}
.classic-border-para-left {
  width: 60px;
  float: left;
  text-align: center;
  font-size: 13px;
  line-height: 23px;
  color: #FFFFFF;
}
.classic-border-para-right {
  width: calc(100% - 60px);
  float: left;
  font-size: 13px;
  line-height: 23px;
  color: #FFFFFF;
  padding-left: 15px;
  border-left: 1px solid #FFFFFF;
}
.classic-ul-box {
  width: 100%;
  float: left;
  margin-top: 15px;
}
.classic-ul-box ol {
  margin: 0;
  padding: 0;
  padding-left: 15px;
}
.classic-ul-box ol li {
  width: 100%;
  float: left;
  margin-top: 10px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 15px;
}
.classic-right-box {
  width: calc(100% - 480px);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.classic-right-box iframe {
  width: 100%;
  border: 15px solid #ebeef0;
}

@media (min-width: 1024px) and (max-width: 1200px) {  
  .classic-left-box {
    width: 440px;
  }
  .classic-right-box {
    width: calc(100% - 410px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .diageo-flavour-text {
    font-size: 16px;
  }
  .diageo-alcohol-tile-img {
    width: 350px;
  }
  .diageo-alcohol-tile-content {
    width: calc(100% - 350px);
  }
  .diageo-alcohol-bottel-title {
    font-size: 17px;
    margin-top: 7px;
  }
  .diageo-alcohol-bottel-value {
    margin-top: 5px;
    font-size: 20px;
  }
  .diageo-alcohol-bottel-btn {
    margin-top: 5px;
  }
  .classic-left-box {
    width: 440px;
  }
  .classic-right-box {
    width: calc(100% - 410px);
  }
}

@media only screen and (max-width: 767px) {
  .top-diageo-title {
    width: calc(100% - 90px);
    font-size: 26px;
  }
  .top-diageo-img {
    width: 90px;
  }
  .diageo-flavour-tile {
    width: 50%;
    padding: 0 7px;
  }
  .diageo-flavour-text {
    font-size: 14px;
  }
  .banner-slider-img img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .banner-slider-box {
    position: static;
    transform: inherit;
    background-color: #ccc;
    width: 100%;
    display: inline-block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .banner-slider-content {
    max-width: 100%;
    padding: 20px;
  }
  .banner-slider-content h1 {
    margin-top: 0;
    font-size: 28px;
  }
  .classic-left-box {
    width: 100%;
  }
  .classic-right-box {
    width: 100%;
    position: static;
    float: left;
    transform: inherit;
  }
  .diageo-alcohol-tile-img {
    width: 100%;
    display: inline-block;
  }
  .diageo-alcohol-tile-content {
    width: 100%;
    display: inline-block;
  }
  .diageo-alcohol-tile:nth-child(odd) .diageo-alcohol-tile-img img {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
  .diageo-alcohol-tile:nth-child(even) .diageo-alcohol-tile-img img {
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
  }
  .diageo-alcohol-tile {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .diageo-alcohol-tile:nth-child(even) {
    display: flex;
    flex-direction: column;
  }
  .diageo-alcohol-tile:nth-child(even) .diageo-alcohol-tile-content {
    order: 2;
  }
  .diageo-alcohol-tile:nth-child(even) .diageo-alcohol-tile-img {
    order: 1;
  }
  .diageo-alcohol-bottel-title {
    font-size: 15px !important;
  }
  .classic-left-box {
    padding: 15px;
  }
  .classic-title {
    font-size: 33px;
    line-height: 33px;
  }
  .diageo-drink-tile {
    width: 50%;
  }
}

@media only screen and (max-width: 479px) {
    .diageo-alcohol-bottel-box {
      width: 100%;
    }
}
/*diageo-18-Nov-21*/